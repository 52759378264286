import firebase from "firebase/compat/app";

import { CMSCollectionRecord, CollectionProperty, ValueType } from "./index";

const isTimestamp = (x: unknown): x is firebase.firestore.Timestamp =>
  typeof x === "object" && x !== null && "toDate" in x;

const getTimestampProperties = (properties: CollectionProperty[]) => {
  const singleDateProperties = properties.filter(
    (el) => el.type === ValueType.date
  );

  const singleDateTimeProperties = properties.filter(
    (el) => el.type === ValueType.dateTime
  );

  const multipleDateProperties = properties.filter(
    (el) =>
      el.type === ValueType.array && el.accept && el.accept === ValueType.date
  );

  const multipleDateTimeProperties = properties.filter(
    (el) =>
      el.type === ValueType.array &&
      el.accept &&
      el.accept === ValueType.dateTime
  );

  return {
    singleDateProperties,
    singleDateTimeProperties,
    multipleDateProperties,
    multipleDateTimeProperties,
  };
};

const getRefProperties = (properties: CollectionProperty[]) => {
  const singleAssetProperties = properties.filter((el) =>
    [
      ValueType.image,
      ValueType.video,
      ValueType.audio,
      ValueType.file,
    ].includes(el.type)
  );

  const multipleAssetProperties = properties.filter(
    (el) =>
      el.type === ValueType.array &&
      el.accept &&
      [
        ValueType.image,
        ValueType.video,
        ValueType.audio,
        ValueType.file,
      ].includes(el.accept)
  );

  return {
    singleAssetProperties,
    multipleAssetProperties,
  };
};

const timestampToDate = (
  record: CMSCollectionRecord,
  properties: CollectionProperty[],
  assetsProperties?: CollectionProperty[]
) => {
  const {
    singleDateProperties,
    singleDateTimeProperties,
    multipleDateProperties,
    multipleDateTimeProperties,
  } = getTimestampProperties(properties);

  singleDateProperties.forEach((el) => {
    if (isTimestamp(record[el.name])) {
      record[el.name] = record[el.name].toDate().toISOString().slice(0, 10);
    }
  });

  singleDateTimeProperties.forEach((el) => {
    if (isTimestamp(record[el.name])) {
      record[el.name] = record[el.name].toDate().toISOString();
    }
  });

  multipleDateProperties.forEach((el) => {
    if (record[el.name]) {
      record[el.name].forEach((el: any) => {
        if (isTimestamp(el)) {
          el = el.toDate().toISOString().slice(0, 10);
        }
      });
    }
  });

  multipleDateTimeProperties.forEach((el) => {
    if (record[el.name]) {
      record[el.name].forEach((el: any) => {
        if (isTimestamp(el)) {
          el = el.toDate().toISOString();
        }
      });
    }
  });

  if (assetsProperties) {
    const { singleAssetProperties, multipleAssetProperties } =
      getRefProperties(properties);

    singleAssetProperties.forEach((el) => {
      if (record[el.name]) {
        timestampToDate(record[el.name], assetsProperties);
      }
    });

    multipleAssetProperties.forEach((el) => {
      if (record[el.name]) {
        record[el.name].forEach((el: any) => {
          timestampToDate(el, assetsProperties);
        });
      }
    });
  }

  return record;
};

const dateToTimestamp = (
  record: CMSCollectionRecord,
  properties: CollectionProperty[],
  assetsProperties?: CollectionProperty[]
) => {
  const { singleDateTimeProperties, multipleDateTimeProperties } =
    getTimestampProperties(properties);

  singleDateTimeProperties.forEach((el) => {
    if (record[el.name]) {
      record[el.name] = firebase.firestore.Timestamp.fromDate(
        new Date(record[el.name])
      );
    }
  });

  multipleDateTimeProperties.forEach((el) => {
    if (record[el.name]) {
      record[el.name].forEach((el: any) => {
        el = firebase.firestore.Timestamp.fromDate(new Date(el));
      });
    }
  });

  if (assetsProperties) {
    const { singleAssetProperties, multipleAssetProperties } =
      getRefProperties(properties);

    singleAssetProperties.forEach((el) => {
      if (record[el.name]) {
        dateToTimestamp(record[el.name], assetsProperties);
      }
    });

    multipleAssetProperties.forEach((el) => {
      if (record[el.name]) {
        record[el.name].forEach((el: any) => {
          dateToTimestamp(el, assetsProperties);
        });
      }
    });
  }
};

export const reconvertRecord = (
  record: CMSCollectionRecord,
  properties: CollectionProperty[],
  assetsProperties: CollectionProperty[]
) => {
  delete record.id;
  dateToTimestamp(record, properties, assetsProperties);
};

export const convertRecord = (
  docSnapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
  properties: CollectionProperty[],
  assetsProperties: CollectionProperty[]
) =>
  docSnapshot.exists
    ? timestampToDate(
        { ...docSnapshot.data(), id: docSnapshot.id },
        properties,
        assetsProperties
      )
    : {};
