import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Badge } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import dayjs from "dayjs";
import ReactDOM from "react-dom/client";

import {
  CollectionWithRecords,
  generateRefreshKey,
  GetVariable,
  GetVariableValue,
} from "../hooks";
import { onPlayElementClick, pauseAudioPlayerIfNoSrc } from "./audioPlayer";
import {
  BrandingColorType,
  BrandingFontStyle,
  BrandingThemeType,
  CalendarStyleConstant,
  ColorVariable,
  ComponentsDirection,
  coordinateHandler,
  getMediaResourceUrl,
  ImageLayout,
  ImageLocation,
  ImageVariable,
  MapStyle,
  Screen,
  ScreenComponent,
  startLoader,
  stopLoader,
  VariableSourceType,
} from "./index";

export const createText = async (
  element: HTMLElement,
  component: ScreenComponent,
  getVariableValue: GetVariableValue
) => {
  const { id, indexInList, text } = component;
  const textElement = document.createElement("div");
  const textRefresh = async () => {
    textElement.innerHTML = await getVariableValue(
      { textConstant: text },
      { [generateRefreshKey(id, "text", indexInList)]: textRefresh }
    );
  };
  await textRefresh();
  element.appendChild(textElement);
};

export const createButton = async (
  element: HTMLElement,
  component: ScreenComponent,
  getVariableValue: GetVariableValue
) => {
  const { id, indexInList, text, image, imageColor, imageLocation } = component;
  element.style.display = "flex";
  element.style.justifyContent = "center";
  element.style.alignItems = "center";
  element.style.gap = "10px";
  element.style.whiteSpace = "nowrap";
  if (image) {
    const imageElement = document.createElement("img");
    imageElement.style.width = "25px";
    imageElement.style.height = "25px";
    imageElement.style.maxHeight = "100%";
    imageElement.style.objectFit = "contain";
    const imageRefresh = async () => {
      const url = await getMediaResourceUrl(
        getVariableValue,
        { [generateRefreshKey(id, "image", indexInList)]: imageRefresh },
        image,
        imageColor,
        imageElement
      );
      imageElement.setAttribute("src", url);
    };
    await imageRefresh();
    element.appendChild(imageElement);
  }
  if (text) {
    const textElement = document.createElement("div");
    if (
      imageLocation !== ImageLocation.leadingAttached &&
      imageLocation !== ImageLocation.trailingAttached
    ) {
      textElement.style.width = "100%";
    }
    if (
      imageLocation === ImageLocation.trailing ||
      imageLocation === ImageLocation.trailingAttached
    ) {
      element.style.flexDirection = "row-reverse";
    }
    if (imageLocation === ImageLocation.top) {
      element.style.flexDirection = "column";
    }
    if (imageLocation === ImageLocation.bottom) {
      element.style.flexDirection = "column-reverse";
    }
    textElement.style.overflow = "hidden";
    textElement.style.whiteSpace = "nowrap";
    textElement.style.textOverflow = "ellipsis";
    const textRefresh = async () => {
      textElement.innerHTML = await getVariableValue(
        { textConstant: text },
        { [generateRefreshKey(id, "text", indexInList)]: textRefresh }
      );
    };
    await textRefresh();
    element.appendChild(textElement);
  }
};

export const createImage = async (
  element: HTMLElement,
  component: ScreenComponent,
  getVariableValue: GetVariableValue
) => {
  element.style.display = "flex";
  const {
    id,
    indexInList,
    image,
    imageColor,
    imageLayout,
    placeholderImage,
    placeholderImageColor,
    placeholderImageLayout,
  } = component;

  const getImage = (imageLayout?: ImageLayout, hidden?: boolean) => {
    const imageElement = document.createElement("img");
    if (hidden) {
      imageElement.hidden = true;
    }
    imageElement.style.width = "100%";
    imageElement.style.height = "100%";
    imageElement.style.borderRadius = "inherit";
    if (imageLayout === ImageLayout.fill) {
      imageElement.style.objectFit = "cover";
    } else {
      imageElement.style.objectFit = "contain";
    }
    element.appendChild(imageElement);
    return imageElement;
  };

  const setImageSource = async (
    imageElement: HTMLImageElement,
    image?: ImageVariable,
    imageColor?: ColorVariable
  ) => {
    const imageRefresh = async () => {
      const url = await getMediaResourceUrl(
        getVariableValue,
        { [generateRefreshKey(id, "image", indexInList)]: imageRefresh },
        image,
        imageColor,
        element
      );
      imageElement.setAttribute("src", url);
    };
    await imageRefresh();
  };

  if (placeholderImage) {
    const placeholderImageElement = getImage(placeholderImageLayout);
    await setImageSource(
      placeholderImageElement,
      placeholderImage,
      placeholderImageColor
    );
    const imageElement = getImage(imageLayout, true);
    imageElement.onload = () => {
      placeholderImageElement.hidden = true;
      imageElement.removeAttribute("hidden");
    };
    await setImageSource(imageElement, image, imageColor);
    const observer = new MutationObserver((changes) => {
      changes.forEach((el) => {
        if (el.attributeName?.includes("src")) {
          imageElement.hidden = true;
          placeholderImageElement.removeAttribute("hidden");
          imageElement.onload = () => {
            placeholderImageElement.hidden = true;
            imageElement.removeAttribute("hidden");
          };
        }
      });
    });
    observer.observe(imageElement, { attributes: true });
  } else {
    const imageElement = getImage(imageLayout);
    await setImageSource(imageElement, image, imageColor);
  }
};

export const createVideo = async (
  element: HTMLElement,
  component: ScreenComponent,
  getVariableValue: GetVariableValue
) => {
  element.style.display = "flex";
  const { id, indexInList, video, autoplay } = component;
  const videoElement = document.createElement("video");
  const videoRefresh = async () => {
    videoElement.src = await getMediaResourceUrl(
      getVariableValue,
      { [generateRefreshKey(id, "video", indexInList)]: videoRefresh },
      video
    );
  };
  await videoRefresh();
  videoElement.controls = true;
  videoElement.playsInline = true;
  if (autoplay?.constant) {
    videoElement.autoplay = true;
  } else {
    videoElement.preload = "none";
  }
  videoElement.style.width = "100%";
  videoElement.style.height = "100%";
  videoElement.style.borderRadius = "inherit";
  element.appendChild(videoElement);
};

export const createAudio = async (
  element: HTMLElement,
  component: ScreenComponent,
  fontStyles: BrandingFontStyle[],
  getVariableValue: GetVariableValue
) => {
  const { id, indexInList, audio } = component;
  const primary = await getVariableValue({
    colorConstant: `@${BrandingColorType.primary}`,
  });
  const onPrimary = await getVariableValue({
    colorConstant: `@${BrandingColorType.onPrimary}`,
  });
  const onBackground = await getVariableValue({
    colorConstant: `@${BrandingColorType.onBackground}`,
  });
  const neutral = await getVariableValue({
    colorConstant: `@${BrandingColorType.neutral}`,
  });

  const playWrapperElement = document.createElement("div");
  playWrapperElement.style.display = "flex";
  playWrapperElement.style.alignItems = "center";
  playWrapperElement.style.gap = "8px";
  element.appendChild(playWrapperElement);

  const playElement = document.createElement("div");
  playElement.style.display = "flex";
  playElement.style.justifyContent = "center";
  playElement.style.alignItems = "center";
  playElement.style.padding = "10px";
  playElement.style.borderRadius = "1000px";
  playElement.style.backgroundColor = primary;
  playElement.innerHTML = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M8 5.14001V19.14L19 12.14L8 5.14001Z" fill="${onPrimary}"/>
  </svg>
  `;
  playWrapperElement.appendChild(playElement);

  const titleWrapperElement = document.createElement("div");
  titleWrapperElement.style.display = "flex";
  titleWrapperElement.style.flexDirection = "column";
  titleWrapperElement.style.gap = "2px";
  playWrapperElement.appendChild(titleWrapperElement);

  const titleElement = document.createElement("div");
  const currentTitleFont = fontStyles.find(
    (el) => el.styleName === "Body"
  )?.ios;
  if (currentTitleFont) {
    titleElement.style.fontSize = `${currentTitleFont.fontSize}px`;
    titleElement.style.fontWeight = `${currentTitleFont.fontWeight}`;
    titleElement.style.fontStyle = currentTitleFont.fontStyle;
    titleElement.style.letterSpacing = `${currentTitleFont.letterSpacing}px`;
    titleElement.style.lineHeight = `${currentTitleFont.lineHeight}%`;
  }
  titleElement.style.color = onBackground;
  titleWrapperElement.appendChild(titleElement);

  const descriptionElement = document.createElement("div");
  const currentDescriptionFont = fontStyles.find(
    (el) => el.styleName === "Footnote"
  )?.ios;
  if (currentDescriptionFont) {
    descriptionElement.style.fontSize = `${currentDescriptionFont.fontSize}px`;
    descriptionElement.style.fontWeight = `${currentDescriptionFont.fontWeight}`;
    descriptionElement.style.fontStyle = currentDescriptionFont.fontStyle;
    descriptionElement.style.letterSpacing = `${currentDescriptionFont.letterSpacing}px`;
    descriptionElement.style.lineHeight = `${currentDescriptionFont.lineHeight}%`;
  }
  descriptionElement.style.color = neutral;
  titleWrapperElement.appendChild(descriptionElement);

  const audioRefresh = async () => {
    const audioValue = await getVariableValue(audio, {
      [generateRefreshKey(id, "audio", indexInList)]: audioRefresh,
    });
    titleElement.innerHTML = `${audioValue?.title || "-"}`;
    descriptionElement.innerHTML = `${audioValue?.bytes || "-"} B`;

    if (audioValue?.url) {
      playElement.className = "clickable";
      playElement.onclick = () =>
        onPlayElementClick(
          audioValue.url,
          playElement,
          onPrimary,
          id,
          indexInList
        );
    } else {
      pauseAudioPlayerIfNoSrc(id, indexInList);
    }
  };
  await audioRefresh();
};

export const listItemName = "List Item";
export const listItemNameCopy = `${listItemName} Copy`;
export const listEmptyName = "List Empty";
export const listHeaderName = "List Header";
export const listFooterName = "List Footer";
export const listSectionHeaderName = "List Section Header";
export const listSectionHeaderNameCopy = `${listSectionHeaderName} Copy`;
export const createList = async (
  element: HTMLElement,
  component: ScreenComponent,
  screenConfig: Screen,
  getVariable: GetVariable,
  getVariableValue: GetVariableValue,
  setSubComponents: (element: HTMLElement, component: ScreenComponent) => void,
  limit: number,
  setLimit: (limit: number) => void
) => {
  const {
    id,
    indexInList,
    listItems,
    listItemContextKey,
    subComponents = [],
    componentsDirection,
    listItemSectionKey,
  } = component;
  const detectLoadMore = async () => {
    const {
      clientWidth,
      clientHeight,
      scrollLeft,
      scrollTop,
      scrollWidth,
      scrollHeight,
    } = element;
    if (
      componentsDirection === ComponentsDirection.vertical
        ? clientHeight + Math.abs(scrollTop) + 10 > scrollHeight
        : clientWidth + Math.abs(scrollLeft) + 10 > scrollWidth
    ) {
      startLoader();
      const newLimit = limit + 30;
      setLimit(newLimit);
      element.removeEventListener("scroll", detectLoadMore);
      await createList(
        element,
        component,
        screenConfig,
        getVariable,
        getVariableValue,
        setSubComponents,
        newLimit,
        setLimit
      );
      stopLoader();
    }
  };
  const refresh = async () => {
    element.removeEventListener("scroll", detectLoadMore);
    await createList(
      element,
      component,
      screenConfig,
      getVariable,
      getVariableValue,
      setSubComponents,
      limit,
      setLimit
    );
  };
  const addListIdAndIndexInListForSubComponents = (
    listId: string,
    indexInList: number,
    subComponents?: ScreenComponent[]
  ): ScreenComponent[] | undefined =>
    subComponents?.map((el) => ({
      ...el,
      listId,
      indexInList,
      subComponents: addListIdAndIndexInListForSubComponents(
        listId,
        indexInList,
        el.subComponents
      ),
    }));
  const listId = id;
  if (listItemContextKey) {
    const listItemsValue = (await getVariableValue(
      listItems,
      { [generateRefreshKey(id, "listItems", indexInList)]: refresh },
      undefined,
      undefined,
      { listId, listItemContextKey, limit }
    )) as CollectionWithRecords | undefined;
    const listSize = listItemsValue?.records.length;
    const listHeader = subComponents.find((el) => el.name === listHeaderName);
    const listEmpty = subComponents.find((el) => el.name === listEmptyName);
    const listItem = subComponents.find((el) => el.name === listItemName);
    const listSectionHeader = subComponents.find(
      (el) => el.name === listSectionHeaderName
    );
    const listFooter = subComponents.find((el) => el.name === listFooterName);
    const components: ScreenComponent[] = [];
    if (listItem) {
      if (listSize) {
        for (let indexInList = 0; indexInList < listSize; indexInList++) {
          if (listSectionHeader) {
            const prev = await getVariable(
              screenConfig,
              listId,
              indexInList - 1
            )(listItemSectionKey);
            const curr = await getVariable(
              screenConfig,
              listId,
              indexInList
            )(listItemSectionKey);
            if (prev !== curr) {
              components.push({
                ...listSectionHeader,
                name: listSectionHeaderNameCopy,
                listId,
                indexInList,
                subComponents: addListIdAndIndexInListForSubComponents(
                  listId,
                  indexInList,
                  listSectionHeader.subComponents
                ),
              });
            }
          }
          components.push({
            ...listItem,
            name: listItemNameCopy,
            listId,
            indexInList,
            subComponents: addListIdAndIndexInListForSubComponents(
              listId,
              indexInList,
              listItem.subComponents
            ),
          });
        }
      }
    }
    const scrollLeft = element.scrollLeft;
    const scrollTop = element.scrollTop;
    element.innerHTML = "";
    await setSubComponents(element, {
      ...component,
      subComponents: [listHeader, listEmpty, ...components, listFooter].filter(
        (el) => !!el
      ) as ScreenComponent[],
    });
    element.scrollLeft = scrollLeft;
    element.scrollTop = scrollTop;
    if (limit === listSize) {
      detectLoadMore();
      element.addEventListener("scroll", detectLoadMore);
    }
  }
};

export const createSpacer = (element: HTMLElement, hasSize: boolean) => {
  if (!hasSize) {
    element.style.flexGrow = "1000000";
  }
};

export const changeThemeEvent = "changeThemeEvent";
export const createThemePicker = async (
  element: HTMLElement,
  getVariableValue: GetVariableValue,
  theme: BrandingThemeType
) => {
  const primary = await getVariableValue({
    colorConstant: `@${BrandingColorType.primary}`,
  });
  const surfaceVariant = await getVariableValue({
    colorConstant: `@${BrandingColorType.surfaceVariant}`,
  });
  const onSurfaceVariant = await getVariableValue({
    colorConstant: `@${BrandingColorType.onSurfaceVariant}`,
  });
  const outline = await getVariableValue({
    colorConstant: `@${BrandingColorType.outline}`,
  });
  const themePickerElement = document.createElement("div");
  themePickerElement.style.width = "100%";
  themePickerElement.style.height = "100%";
  themePickerElement.style.display = "flex";
  themePickerElement.innerHTML = `
  <svg width="100%" viewBox="0 0 358 100" fill="none">
    <g style="cursor: pointer;" onclick="document.dispatchEvent(new CustomEvent('${changeThemeEvent}', { detail: ${
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  }}))">
      <rect x="0.5" y="0.5" width="173" height="99" rx="9.5" fill="${surfaceVariant}" stroke="${surfaceVariant}"/>
      <text fill="${onSurfaceVariant}" xml:space="preserve" style="white-space: pre" font-size="13" letter-spacing="-0.078px"><tspan x="16" y="42.6211">System</tspan></text>
      <text fill="${outline}" xml:space="preserve" style="white-space: pre" font-size="13" letter-spacing="-0.078px"><tspan x="16" y="65.6211">The same as on </tspan><tspan x="16" y="81.6211">the device</tspan></text>
    </g>
    <g style="cursor: pointer;" onclick="document.dispatchEvent(new CustomEvent('${changeThemeEvent}', { detail: true }))">
      <rect x="186" y="0.5" width="80" height="99" rx="9.5" fill="${surfaceVariant}" stroke="${
    theme === BrandingThemeType.dark ? primary : surfaceVariant
  }"/>
      <path d="M235.085 40.79C234.928 42.4922 234.289 44.1144 233.243 45.4668C232.197 46.8192 230.788 47.8458 229.181 48.4265C227.573 49.0073 225.833 49.1181 224.164 48.7461C222.496 48.3741 220.968 47.5345 219.759 46.3258C218.55 45.117 217.711 43.589 217.339 41.9205C216.967 40.252 217.078 38.5121 217.658 36.9043C218.239 35.2965 219.266 33.8874 220.618 32.8418C221.97 31.7961 223.593 31.1573 225.295 31C224.298 32.3483 223.819 34.0094 223.943 35.6814C224.068 37.3534 224.789 38.9251 225.974 40.1106C227.16 41.2961 228.731 42.0168 230.403 42.1415C232.075 42.2662 233.737 41.7866 235.085 40.79Z" fill="${
        theme === BrandingThemeType.dark ? onSurfaceVariant : outline
      }" stroke="${
    theme === BrandingThemeType.dark ? onSurfaceVariant : outline
  }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <text fill="${onSurfaceVariant}" xml:space="preserve" style="white-space: pre" font-size="13" letter-spacing="-0.078px"><tspan x="211.803" y="81.6211">Dark</tspan></text>
   </g>
    <g style="cursor: pointer;" onclick="document.dispatchEvent(new CustomEvent('${changeThemeEvent}', { detail: false }))">
      <rect x="278.5" y="0.5" width="79" height="99" rx="9.5" fill="${surfaceVariant}" stroke="${
    theme === BrandingThemeType.light ? primary : surfaceVariant
  }"/>
      <path d="M318 45C320.761 45 323 42.7614 323 40C323 37.2386 320.761 35 318 35C315.239 35 313 37.2386 313 40C313 42.7614 315.239 45 318 45Z" fill="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke="${
    theme === BrandingThemeType.dark ? outline : onSurfaceVariant
  }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M318 29V31" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M318 49V51" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M310.22 32.2197L311.64 33.6397" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M324.36 46.3604L325.78 47.7804" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M307 40H309" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M327 40H329" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M310.22 47.7804L311.64 46.3604" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> 
      <path d="M324.36 33.6397L325.78 32.2197" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <text fill="${onSurfaceVariant}" xml:space="preserve" style="white-space: pre" font-size="13" letter-spacing="-0.078px"><tspan x="302.706" y="81.6211">Light</tspan></text>
    </g>
  </svg>
  `;
  element.appendChild(themePickerElement);
};

export const createNotificationsSettings = async (
  element: HTMLElement,
  getVariableValue: GetVariableValue
) => {
  const primary = await getVariableValue({
    colorConstant: `@${BrandingColorType.primary}`,
  });
  const onPrimary = await getVariableValue({
    colorConstant: `@${BrandingColorType.onPrimary}`,
  });
  const surfaceVariant = await getVariableValue({
    colorConstant: `@${BrandingColorType.surfaceVariant}`,
  });
  const onSurfaceVariant = await getVariableValue({
    colorConstant: `@${BrandingColorType.onSurfaceVariant}`,
  });
  const outline = await getVariableValue({
    colorConstant: `@${BrandingColorType.outline}`,
  });
  const notificationsSettingsElement = document.createElement("div");
  notificationsSettingsElement.style.width = "100%";
  notificationsSettingsElement.style.height = "100%";
  notificationsSettingsElement.style.display = "flex";
  notificationsSettingsElement.innerHTML = `
  <svg width="100%" viewBox="0 0 358 160" fill="none">
    <rect width="358" height="160" rx="10" fill="${surfaceVariant}"/>
    <text fill="${onSurfaceVariant}" xml:space="preserve" style="white-space: pre" font-size="15" letter-spacing="-0.24px" font-weight="600"><tspan x="69" y="30.832">Enable push notification</tspan></text>
    <text fill="${outline}" xml:space="preserve" style="white-space: pre" font-size="13" letter-spacing="-0.078px"><tspan x="69" y="52.6211">Lorem ipsum, or lipsum as it is sometimes </tspan><tspan x="69" y="68.6211">known, is dummy text used in laying out </tspan><tspan x="69" y="84.6211">print, graphic or web designs.</tspan></text>
    <rect x="68" y="108" width="180" height="30" rx="15" fill="${primary}"/>
    <text fill="${onPrimary}" xml:space="preserve" style="white-space: pre" font-size="15" letter-spacing="-0.24px" font-weight="600"><tspan x="112.001" y="127.332">Go to Settings</tspan></text>
    <path d="M35.1817 36C38.8642 36 41.8273 38.8977 41.8273 42.4752V44.3152C43.8941 44.9604 45.4 46.8314 45.4 49.0661V54.9904C45.4 57.7569 43.1064 60 40.2787 60H30.1226C27.2936 60 25 57.7569 25 54.9904V49.0661C25 46.8314 26.5071 44.9604 28.5727 44.3152V42.4752C28.5849 38.8977 31.548 36 35.1817 36ZM35.1939 49.6611C34.6086 49.6611 34.1331 50.1262 34.1331 50.6986V53.3459C34.1331 53.9302 34.6086 54.3953 35.1939 54.3953C35.7914 54.3953 36.2669 53.9302 36.2669 53.3459V50.6986C36.2669 50.1262 35.7914 49.6611 35.1939 49.6611ZM35.2061 38.0869C32.7308 38.0869 30.7188 40.0425 30.7066 42.4514V44.0564H39.6934V42.4752C39.6934 40.0545 37.6814 38.0869 35.2061 38.0869Z" fill="${primary}"/>
  </svg>
  `;
  element.appendChild(notificationsSettingsElement);
};

export const createOffer = async (
  element: HTMLElement,
  component: ScreenComponent,
  fontStyles: BrandingFontStyle[],
  getVariableValue: GetVariableValue
) => {
  element.style.borderRadius = "16px";
  const { id, indexInList, offer, selected } = component;
  const primary = await getVariableValue({
    colorConstant: `@${BrandingColorType.primary}`,
  });
  const neutral = await getVariableValue({
    colorConstant: `@${BrandingColorType.neutral}`,
  });
  const white = await getVariableValue({
    colorConstant: `@${BrandingColorType.white}`,
  });
  const inverseOnSurface = await getVariableValue({
    colorConstant: `@${BrandingColorType.inverseOnSurface}`,
  });
  const onBackground = await getVariableValue({
    colorConstant: `@${BrandingColorType.onBackground}`,
  });

  const offerElement = document.createElement("div");
  offerElement.style.display = "flex";
  offerElement.style.justifyContent = "space-between";
  offerElement.style.alignItems = "center";
  offerElement.style.gap = "13px";
  offerElement.style.padding = "11px 13px 11px 16px";
  element.appendChild(offerElement);

  const titleWrapperElement = document.createElement("div");
  titleWrapperElement.style.display = "flex";
  titleWrapperElement.style.flexDirection = "column";
  titleWrapperElement.style.gap = "3px";
  offerElement.appendChild(titleWrapperElement);

  const titleElement = document.createElement("div");
  const currentTitleFont = fontStyles.find(
    (el) => el.styleName === "Title 3 Bold"
  )?.ios;
  if (currentTitleFont) {
    titleElement.style.fontSize = `${currentTitleFont.fontSize}px`;
    titleElement.style.fontWeight = `${currentTitleFont.fontWeight}`;
    titleElement.style.fontStyle = currentTitleFont.fontStyle;
    titleElement.style.letterSpacing = `${currentTitleFont.letterSpacing}px`;
    titleElement.style.lineHeight = `${currentTitleFont.lineHeight}%`;
  }
  titleElement.style.color = onBackground;
  titleWrapperElement.appendChild(titleElement);

  const descriptionElement = document.createElement("div");
  const currentDescriptionFont = fontStyles.find(
    (el) => el.styleName === "Subheadline"
  )?.ios;
  if (currentDescriptionFont) {
    descriptionElement.style.fontSize = `${currentDescriptionFont.fontSize}px`;
    descriptionElement.style.fontWeight = `${currentDescriptionFont.fontWeight}`;
    descriptionElement.style.fontStyle = currentDescriptionFont.fontStyle;
    descriptionElement.style.letterSpacing = `${currentDescriptionFont.letterSpacing}px`;
    descriptionElement.style.lineHeight = `${currentDescriptionFont.lineHeight}%`;
  }
  descriptionElement.style.color = neutral;
  titleWrapperElement.appendChild(descriptionElement);

  const checkMarkElement = document.createElement("div");
  checkMarkElement.style.display = "flex";
  offerElement.appendChild(checkMarkElement);

  const offerRefresh = async () => {
    titleElement.innerHTML = "";
    descriptionElement.innerHTML = "";
    const offerValue = (await getVariableValue(
      { source: offer },
      { [generateRefreshKey(id, "offer", indexInList)]: offerRefresh },
      undefined,
      true
    )) as CollectionWithRecords | undefined;
    if (offerValue) {
      const { collectionId, records } = offerValue;
      const offerRecord = records[0];
      const [name, constant] = collectionId.split("/");
      const productValue = (await getVariableValue(
        {
          source: {
            type: VariableSourceType.collection,
            collection: { name },
            selector: { constant },
          },
        },
        undefined,
        undefined,
        true
      )) as CollectionWithRecords | undefined;
      if (productValue) {
        const { records } = productValue;
        const productRecord = records[0];
        if (
          productRecord.name &&
          offerRecord.duration &&
          offerRecord.price &&
          offerRecord.currency
        ) {
          titleElement.innerHTML = `${productRecord.name} ${offerRecord.duration}`;
          descriptionElement.innerHTML = `${offerRecord.price}${offerRecord.currency}`;
          if (offerRecord.trialDuration) {
            titleElement.innerHTML += " Trial";
            descriptionElement.innerHTML += ` after ${offerRecord.trialDuration}`;
          }
        }
      }
    }
  };
  await offerRefresh();

  const selectedRefresh = async () => {
    const selectedValue = await getVariableValue(
      { ...selected, booleanConstant: selected?.constant },
      { [generateRefreshKey(id, "selected", indexInList)]: selectedRefresh }
    );
    if (selectedValue) {
      element.style.background = "transparent";
      element.style.border = `2px solid ${primary}`;
      checkMarkElement.innerHTML = `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect width="24" height="24" rx="12" fill="${primary}"/>
          <path d="M18.75 8.24988L9.75 17.2499L5.625 13.1249L6.6825 12.0674L9.75 15.1274L17.6925 7.19238L18.75 8.24988Z" fill="${white}"/>
        </svg>`;
    } else {
      element.style.background = inverseOnSurface;
      element.style.border = `2px solid ${inverseOnSurface}`;
      checkMarkElement.innerHTML = `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <circle cx="12" cy="12" r="11.25" stroke="${neutral}" stroke-width="1.5"/>
        </svg>`;
    }
  };
  await selectedRefresh();
};

export const createTextInput = async (
  element: HTMLElement,
  component: ScreenComponent,
  fontStyles: BrandingFontStyle[],
  isDatePicker: boolean,
  getVariableValue: GetVariableValue
) => {
  element.style.display = "flex";
  const {
    id,
    indexInList,
    name,
    text,
    date,
    placeholderText,
    placeholderTextFont,
    placeholderTextColor,
    textInputType,
    textInputMultiline,
  } = component;
  let input: HTMLInputElement | HTMLTextAreaElement;
  const textInputMultilineValue = await getVariableValue({
    ...textInputMultiline,
    booleanConstant: textInputMultiline?.constant,
  });
  if (textInputMultilineValue) {
    input = document.createElement("textarea");
    input.rows = 1;
    input.style.resize = "none";
  } else {
    input = document.createElement("input");
    if (isDatePicker) {
      input.type = "date";
    } else if (textInputType) {
      input.type = textInputType;
    }
  }
  const textRefresh = async () => {
    input.value =
      (isDatePicker
        ? await getVariableValue(
            { ...date, dateConstant: date?.constant },
            { [generateRefreshKey(id, "date", indexInList)]: textRefresh }
          )
        : await getVariableValue(
            { textConstant: text },
            { [generateRefreshKey(id, "text", indexInList)]: textRefresh }
          )) || "";
  };
  await textRefresh();
  const placeholderTextRefresh = async () => {
    input.placeholder =
      (await getVariableValue(
        { textConstant: placeholderText },
        {
          [generateRefreshKey(id, "placeholderText", indexInList)]:
            placeholderTextRefresh,
        }
      )) || "";
  };
  await placeholderTextRefresh();
  input.style.font = "inherit";
  input.style.color = "inherit";
  input.style.borderRadius = "inherit";
  input.style.outline = "none";
  input.style.boxSizing = "border-box";
  input.style.width = "100%";
  input.style.border = "none";
  input.style.background = "transparent";
  input.style.padding = element.style.padding || "0";
  element.style.removeProperty("padding");
  const source = {
    type: VariableSourceType.component,
    componentName: name,
    fieldName: isDatePicker ? "date" : "text",
  };
  const setValue = (input: HTMLInputElement | HTMLTextAreaElement) =>
    getVariableValue({ source }, undefined, {
      value: isDatePicker
        ? input.value
          ? new Date(input.value).toISOString().slice(0, 10)
          : ""
        : input.value,
    });
  await setValue(input);
  let changed = false;
  input.oninput = async (e) => {
    await setValue(e.target as HTMLInputElement | HTMLTextAreaElement);
    if (!changed) {
      const refresh = async () =>
        (input.value = isDatePicker
          ? await getVariableValue(
              { source },
              { [generateRefreshKey(id, "date", indexInList)]: refresh }
            )
          : (await getVariableValue(
              { source },
              { [generateRefreshKey(id, "text", indexInList)]: refresh }
            )) || "");
      await refresh();
    }
    changed = true;
  };
  element.appendChild(input);

  const style = document.createElement("style");
  const currentPlaceholderFont = fontStyles.find(
    (el) => el.styleName === placeholderTextFont?.slice(1)
  )?.ios;
  style.innerHTML = `
    div[id="${element.id}"] > *::placeholder {
      ${
        currentPlaceholderFont &&
        `font-size: ${currentPlaceholderFont.fontSize}px;
        font-weight: ${currentPlaceholderFont.fontWeight};
        font-style: ${currentPlaceholderFont.fontStyle};
        letter-spacing: ${currentPlaceholderFont.letterSpacing}px;
        line-height: ${currentPlaceholderFont.lineHeight}%;`
      }
      ${
        placeholderTextColor &&
        `color: ${await getVariableValue({
          ...placeholderTextColor,
          colorConstant: placeholderTextColor.constant,
        })}`
      }
    }`;
  element.appendChild(style);
};

export const createCarousel = async (
  element: HTMLElement,
  component: ScreenComponent,
  getVariableValue: GetVariableValue
) => {
  element.style.display = "flex";
  const { id, subComponents = [], duration, loop } = component;
  const primary = await getVariableValue({
    colorConstant: `@${BrandingColorType.primary}`,
  });
  const surfaceVariant = await getVariableValue({
    colorConstant: `@${BrandingColorType.surfaceVariant}`,
  });
  const slidesLength = subComponents.length;
  if (slidesLength) {
    const durationValue = await getVariableValue({
      ...duration,
      numberConstant: duration?.constant,
    });
    const loopValue = await getVariableValue({
      ...loop,
      booleanConstant: loop?.constant,
    });
    createProgress(
      id,
      element,
      primary,
      surfaceVariant,
      slidesLength,
      durationValue,
      loopValue,
      true
    );
  }
};

export const createMap = async (
  element: HTMLElement,
  component: ScreenComponent,
  screenConfig: Screen,
  getVariable: GetVariable,
  getVariableValue: GetVariableValue
) => {
  const {
    id,
    mapStyle,
    showUserLocation,
    coordinate,
    zoom,
    listItems,
    listItemContextKey,
    listItemCoordinate,
  } = component;
  const zoomValue = await getVariableValue({
    ...zoom,
    numberConstant: zoom?.constant,
  });
  const showUserLocationValue = await getVariableValue({
    ...showUserLocation,
    booleanConstant: showUserLocation?.constant,
  });
  const listId = id;
  const mapTypeId =
    !mapStyle || mapStyle === MapStyle.standard ? "roadmap" : mapStyle;
  const coordinates: {
    label: string;
    position: { lat: number; lng: number };
  }[] = [];
  const center = { lat: 0, lng: 0 };
  const coordinateValue = await getVariableValue({
    ...coordinate,
    coordinateConstant: coordinate?.constant,
  });
  if (coordinateValue) {
    if (Array.isArray(coordinateValue)) {
      coordinateValue.forEach((el, i) =>
        coordinates.push({
          label: `C${i}`,
          position: coordinateHandler(el, center),
        })
      );
    } else {
      coordinates.push({
        label: "C",
        position: coordinateHandler(coordinateValue, center),
      });
    }
  }
  const mapElement = document.createElement("div");
  mapElement.style.display = "flex";
  mapElement.style.width = "100%";
  mapElement.style.height = "100%";
  const map = new google.maps.Map(mapElement, {
    mapTypeId,
    center,
    zoom: zoomValue || 1,
    disableDefaultUI: true,
  });
  if (showUserLocationValue) {
    await navigator.geolocation.getCurrentPosition((position) => {
      const center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      map.setCenter(center);
      coordinates.push({ label: "ME", position: center });
    });
  }
  if (listItemContextKey) {
    const listItemsValue = (await getVariableValue(
      listItems,
      undefined,
      undefined,
      undefined,
      { listId, listItemContextKey }
    )) as CollectionWithRecords | undefined;
    const listSize = listItemsValue?.records.length;
    if (listSize) {
      for (let indexInList = 0; indexInList < listSize; indexInList++) {
        const value = await getVariable(
          screenConfig,
          listId,
          indexInList
        )({
          ...listItemCoordinate,
          coordinateConstant: listItemCoordinate?.constant,
        });
        if (value) {
          if (Array.isArray(value)) {
            value.forEach((el, i) =>
              coordinates.push({
                label: `I${indexInList}I${i}`,
                position: coordinateHandler(el, { lat: 0, lng: 0 }),
              })
            );
          } else {
            coordinates.push({
              label: `I${indexInList}`,
              position: coordinateHandler(value, { lat: 0, lng: 0 }),
            });
          }
        }
      }
    }
  }
  const markers = coordinates.map((el) => new google.maps.Marker(el));
  new MarkerClusterer({ markers, map });
  element.appendChild(mapElement);
};

export const createCalendar = async (
  element: HTMLElement,
  component: ScreenComponent,
  fontStyles: BrandingFontStyle[],
  screenConfig: Screen,
  getVariable: GetVariable,
  getVariableValue: GetVariableValue
) => {
  const {
    id,
    calendarStyle,
    date,
    displayDate,
    headerFont,
    headerColor,
    showHeader,
    showWeekdays,
    listItems,
    listItemContextKey,
    listItemDate,
  } = component;
  const listId = id;
  const primary = await getVariableValue({
    colorConstant: `@${BrandingColorType.primary}`,
  });
  const onPrimary = await getVariableValue({
    colorConstant: `@${BrandingColorType.onPrimary}`,
  });
  const calendar = document.createElement("div");
  element.appendChild(calendar);
  const calendarStyleValue = (await getVariableValue({
    ...calendarStyle,
    calendarStyleConstant: calendarStyle?.constant,
  })) as CalendarStyleConstant;
  const values: dayjs.Dayjs[] = [];
  const dateValue = await getVariableValue({
    ...date,
    dateConstant: date?.constant,
  });
  if (dateValue) {
    values.push(dayjs(dateValue));
  }
  const displayDateValue = await getVariableValue({
    ...displayDate,
    dateConstant: displayDate?.constant,
  });
  if (displayDateValue) {
    values.push(dayjs(displayDateValue));
  }
  if (listItemContextKey) {
    const listItemsValue = (await getVariableValue(
      listItems,
      undefined,
      undefined,
      undefined,
      { listId, listItemContextKey }
    )) as CollectionWithRecords | undefined;
    const listSize = listItemsValue?.records.length;
    if (listSize) {
      for (let indexInList = 0; indexInList < listSize; indexInList++) {
        const value = await getVariable(
          screenConfig,
          listId,
          indexInList
        )({
          ...listItemDate,
          dateConstant: listItemDate?.constant,
        });
        if (value) {
          values.push(dayjs(value));
        }
      }
    }
  }
  const currentHeaderFont = fontStyles.find(
    (el) => el.styleName === headerFont?.slice(1)
  )?.ios;
  if (calendarStyleValue) {
    const showHeaderValue = await getVariableValue({
      ...showHeader,
      booleanConstant: showHeader?.constant,
    });
    const showWeekdaysValue = await getVariableValue({
      ...showWeekdays,
      booleanConstant: showWeekdays?.constant,
    });
    ReactDOM.createRoot(calendar).render(
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          showDaysOutsideCurrentMonth
          slots={{
            day: (props: any) => (
              <Badge
                overlap="circular"
                badgeContent={
                  !!values.find(
                    ({ $D, $M, $y }: any) =>
                      $D === props.day.$D &&
                      $M === props.day.$M &&
                      $y === props.day.$y
                  )
                    ? "🌚"
                    : undefined
                }
              >
                <PickersDay {...props} />
              </Badge>
            ),
          }}
          sx={{
            width: "auto !important",
            height: "auto !important",
            "& *": { color: "inherit !important", font: "inherit !important" },
            "& div.MuiPickersCalendarHeader-root": {
              display: !showHeaderValue ? "none !important" : undefined,
              padding: "unset !important",
              margin: "8px 0 8px 20px !important",
              color: headerColor
                ? `${await getVariableValue({
                    ...headerColor,
                    colorConstant: headerColor.constant,
                  })} !important`
                : undefined,
              fontSize: currentHeaderFont
                ? `${currentHeaderFont.fontSize}px !important`
                : undefined,
              fontWeight: currentHeaderFont
                ? `${currentHeaderFont.fontWeight} !important`
                : undefined,
              fontStyle: currentHeaderFont
                ? `${currentHeaderFont.fontStyle} !important`
                : undefined,
              letterSpacing: currentHeaderFont
                ? `${currentHeaderFont.letterSpacing}px !important`
                : undefined,
              lineHeight: currentHeaderFont
                ? `${currentHeaderFont.lineHeight}% !important`
                : undefined,
            },
            "& div.MuiYearCalendar-root": {
              width: "auto !important",
            },
            "& div.MuiDayCalendar-header": {
              display: !showWeekdaysValue ? "none !important" : undefined,
            },
            '& div[role="row"]': {
              justifyContent: "space-between !important",
            },
            '& button[aria-current="date"], & button[aria-selected="true"], & button[aria-checked="true"]':
              {
                color: `${onPrimary} !important`,
                borderColor: `${primary} !important`,
                background: `${primary} !important`,
              },
            "& button.MuiPickersDay-dayOutsideMonth": {
              opacity: "0.6 !important",
            },
          }}
        />
      </LocalizationProvider>
    );
  }
};

export const createSlider = async (
  element: HTMLElement,
  component: ScreenComponent,
  getVariableValue: GetVariableValue
) => {
  element.style.display = "flex";
  const {
    id,
    indexInList,
    name,
    minimumValue,
    maximumValue,
    value,
    discrete,
    primaryColor,
    secondaryColor,
    headerColor,
  } = component;
  const input = document.createElement("input");
  const valueRefresh = async () => {
    input.value =
      (await getVariableValue(
        { ...value, numberConstant: value?.constant },
        { [generateRefreshKey(id, "value", indexInList)]: valueRefresh }
      )) || 0;
  };
  await valueRefresh();
  const minimumValueValue = await getVariableValue({
    ...minimumValue,
    numberConstant: minimumValue?.constant,
  });
  const maximumValueValue = await getVariableValue({
    ...maximumValue,
    numberConstant: maximumValue?.constant,
  });
  const discreteValue = await getVariableValue({
    ...discrete,
    booleanConstant: discrete?.constant,
  });
  const trackColor = await getVariableValue({
    ...primaryColor,
    colorConstant: primaryColor?.constant || `@${BrandingColorType.primary}`,
  });
  const railColor = await getVariableValue({
    ...secondaryColor,
    colorConstant: secondaryColor?.constant || `@${BrandingColorType.neutral}`,
  });
  const thumbColor = await getVariableValue({
    ...headerColor,
    colorConstant: headerColor?.constant || `@${BrandingColorType.background}`,
  });
  const neutral = await getVariableValue({
    colorConstant: `@${BrandingColorType.neutral}`,
  });

  input.min = minimumValueValue || 0;
  input.max = maximumValueValue || 1;
  input.type = "range";
  if (discreteValue) {
    input.step = "1";
  }
  const source = {
    type: VariableSourceType.component,
    componentName: name,
    fieldName: "value",
  };
  const setValue = (input: HTMLInputElement) =>
    getVariableValue({ source }, undefined, { value: +input.value });
  await setValue(input);
  let changed = false;
  input.oninput = async (e) => {
    await setValue(e.target as HTMLInputElement);
    if (!changed) {
      const refresh = async () =>
        (input.value =
          (await getVariableValue(
            { source },
            { [generateRefreshKey(id, "value", indexInList)]: refresh }
          )) || 0);
      await refresh();
    }
    changed = true;
  };
  element.appendChild(input);

  const style = document.createElement("style");
  const trackStyle = `
    background: ${trackColor};`;
  const thumbStyle = `
      appearance: none;
      width: 28px;
      height: 28px;
      margin-top: -12px;
      border-radius: 50%;
      background: ${thumbColor};
      box-shadow: 0 0 2px 0 ${neutral};
      cursor: pointer;`;
  const railStyle = `
      height: 4px;
      border-radius: 4px;
      background: ${railColor};`;
  style.innerHTML = `
    div[id="${element.id}"] > input {
      appearance: none;
      background: none;
      width: 100%;
      height: 28px;
      margin: 0;
    }
    div[id="${element.id}"] > input::-moz-range-progress { ${trackStyle}
    }
    div[id="${element.id}"] > input::-webkit-slider-thumb { ${thumbStyle}
    }
    div[id="${element.id}"] > input::-moz-range-thumb { ${thumbStyle}
    }
    div[id="${element.id}"] > input::-webkit-slider-runnable-track { ${railStyle}
    }
    div[id="${element.id}"] > input::-moz-range-track { ${railStyle}
    }`;
  element.appendChild(style);
};

export const createToggle = async (
  element: HTMLElement,
  component: ScreenComponent,
  getVariableValue: GetVariableValue
) => {
  element.style.display = "flex";
  const { id, indexInList, name, selected, primaryColor, headerColor } =
    component;
  const trackColor = await getVariableValue({
    ...primaryColor,
    colorConstant: primaryColor?.constant || `@${BrandingColorType.primary}`,
  });
  const thumbColor = await getVariableValue({
    ...headerColor,
    colorConstant: headerColor?.constant || `@${BrandingColorType.background}`,
  });
  const selectedValue = await getVariableValue({
    ...selected,
    booleanConstant: selected?.constant,
  });

  const label = document.createElement("label");
  element.appendChild(label);

  const input = document.createElement("input");
  input.checked = selectedValue;
  input.type = "checkbox";
  const source = {
    type: VariableSourceType.component,
    componentName: name,
    fieldName: "selected",
  };
  const setValue = (input: HTMLInputElement) =>
    getVariableValue({ source }, undefined, { value: input.checked });
  await setValue(input);
  let changed = false;
  input.oninput = async (e) => {
    await setValue(e.target as HTMLInputElement);
    if (!changed) {
      const refresh = async () =>
        (input.checked = await getVariableValue(
          { source },
          { [generateRefreshKey(id, "selected", indexInList)]: refresh }
        ));
      await refresh();
    }
    changed = true;
  };
  label.appendChild(input);

  const span = document.createElement("span");
  label.appendChild(span);

  const style = document.createElement("style");
  style.innerHTML = `
  div[id="${element.id}"] > label {
    position: relative;
    width: 52px;
    height: 32px;
  }
  div[id="${element.id}"] > label > input {
    display: none;
  }
  div[id="${element.id}"] > label > span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #78788029;
    transition: 400ms;
    border-radius: 16px;
  }
  div[id="${element.id}"] > label > span:before {
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    top: 2px;
    left: 2px;
    background: ${thumbColor};
    transition: 400ms;
    border-radius: 50%;
  }
  div[id="${element.id}"] > label > input:checked + span {
    background: ${trackColor};
  }
  div[id="${element.id}"] > label > input:focus + span {
    box-shadow: 0 0 1px ${trackColor};
  }
  div[id="${element.id}"] > label > input:checked + span:before {
    transform: translateX(20px);
  }`;
  element.appendChild(style);
};

export const createProgressIndicator = async (
  element: HTMLElement,
  component: ScreenComponent,
  getVariableValue: GetVariableValue
) => {
  const { id, maximumValue, primaryColor, secondaryColor, duration, loop } =
    component;
  const durationValue = await getVariableValue({
    ...duration,
    numberConstant: duration?.constant,
  });
  const loopValue = await getVariableValue({
    ...loop,
    booleanConstant: loop?.constant,
  });
  const maximumValueValue = await getVariableValue({
    ...maximumValue,
    numberConstant: maximumValue?.constant,
  });
  const segments = maximumValueValue || 1;
  const trackColor = await getVariableValue({
    ...primaryColor,
    colorConstant: primaryColor?.constant || `@${BrandingColorType.primary}`,
  });
  const railColor = await getVariableValue({
    ...secondaryColor,
    colorConstant: secondaryColor?.constant || `@${BrandingColorType.neutral}`,
  });
  createProgress(
    id,
    element,
    trackColor,
    railColor,
    segments,
    durationValue,
    loopValue
  );
};

export const progressTrackAttribute = "track";
export const progressActiveAttribute = "active";
export const progressPassedAttribute = "passed";
const createProgress = (
  id: string,
  element: HTMLElement,
  trackColor: string,
  railColor: string,
  segments: number,
  duration?: number,
  infinite?: boolean,
  absolute?: boolean
) => {
  const progress = document.createElement("i");
  element.appendChild(progress);
  progress.style.display = "flex";
  progress.style.gap = "4px";
  if (absolute) {
    progress.style.position = "absolute";
    progress.style.top = "0";
    progress.style.left = "0";
    progress.style.width = "calc(100% - 16px)";
    progress.style.margin = "0 8px";
  }
  new Array(segments).fill(0).forEach(() => {
    const rail = document.createElement("i");
    rail.style.display = "flex";
    rail.style.width = "100%";
    rail.style.height = "4px";
    rail.style.borderRadius = "2px";
    rail.style.backgroundColor = railColor;
    progress.appendChild(rail);
    const track = document.createElement("i");
    track.style.display = "flex";
    track.style.height = "4px";
    track.style.borderRadius = "2px";
    track.style.backgroundColor = trackColor;
    track.setAttribute(progressTrackAttribute, "true");
    rail.appendChild(track);
  });
  const style = document.createElement("style");
  style.innerHTML = `
  div[id='${element.id}'] > i > i > i[${progressActiveAttribute}] {
    width: 100%;
    ${
      duration
        ? `animation: animation-${id} ${duration}s linear${
            segments === 1 && infinite ? " infinite" : ""
          };`
        : ""
    }
  }
   ${
     duration
       ? `@keyframes animation-${id} {
        0% {
          width: 0;
        }
        100% {
          width: 100%;
        }
      }`
       : ""
   }
  div[id='${element.id}'] > i > i > i[${progressPassedAttribute}] {
    width: 100%;
  }`;
  progress.appendChild(style);
};
