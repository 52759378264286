import { useContext, useEffect, useMemo, useRef, useState } from "react";
import slugify from "react-slugify";

import { FirebaseContext } from "./context";
import { GetVariable, Update } from "./hooks";
import { BrandingThemeType, Config, Screen, getScreen } from "./utils";

interface Props {
  inDevice: boolean;
  language: string;
  theme: BrandingThemeType;
  desktopMode: boolean;
  config: Config;
  screens: Screen[];
  page: string;
  launchOpen: boolean;
  setLaunchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: (pathname: string) => void;
  navigateBack: () => void;
  closeDialog: () => void;
  closeAllDialogs: () => void;
  getVariable: GetVariable;
  update: Update;
}

export const Dialog: React.FC<Props> = ({
  inDevice,
  language,
  theme,
  desktopMode,
  config,
  screens,
  page,
  launchOpen,
  setLaunchOpen,
  navigate,
  navigateBack,
  closeDialog,
  closeAllDialogs,
  getVariable,
  update,
}) => {
  const screenParentElementRef = useRef<HTMLDivElement>(null);
  const { f } = useContext(FirebaseContext);
  const [dialog, setDialog] = useState<string | null>(null);

  const screen = useMemo(
    () => screens.find((el) => slugify(el.screenName) === page),
    [page]
  );

  useEffect(() => {
    const screenParentElement = screenParentElementRef.current;
    if (screenParentElement && screen) {
      getScreen(
        f,
        screen,
        config,
        screenParentElement,
        desktopMode,
        theme,
        launchOpen,
        setLaunchOpen,
        navigate,
        navigateBack,
        setDialog,
        closeDialog,
        closeAllDialogs,
        getVariable,
        update
      );
    }
  }, [screen, language, theme, inDevice, desktopMode]);

  return (
    <>
      <div className="dialog" ref={screenParentElementRef} />
      {dialog && (
        <Dialog
          inDevice={inDevice}
          language={language}
          theme={theme}
          desktopMode={desktopMode}
          config={config}
          screens={screens}
          page={dialog}
          navigate={navigate}
          navigateBack={navigateBack}
          launchOpen={launchOpen}
          setLaunchOpen={setLaunchOpen}
          closeDialog={() => setDialog(null)}
          closeAllDialogs={closeAllDialogs}
          getVariable={getVariable}
          update={update}
        />
      )}
    </>
  );
};
