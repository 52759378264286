import { Collection } from "./index";

const collectionNameToFind = (name: string) =>
  name
    .split("/")
    .filter((el, i) => !(i % 2))
    .join("/");

export const getCollectionProperties = (
  collections: Collection[],
  collectionId: string
) =>
  collections.find(
    (el) => collectionNameToFind(el.name) === collectionNameToFind(collectionId)
  )?.properties || [];
