import slugify from "react-slugify";

import { F } from "../context";
import {
  generateRefreshKey,
  getParams,
  GetVariable,
  GetVariableValue,
  setInputParameters,
  Update,
} from "../hooks";
import {
  checkIdfaAndCookieTrackingEnabled,
  enableIdfaAndCookieTracking,
} from "../services/permissions";
import { checkoutSelected } from "../services/stripe";
import {
  Action,
  ActionType,
  findComponent,
  getInputParameters,
  readableError,
  Screen,
  ScreenParameter,
  VariableSourceType,
} from "./index";

export const runActions = async (
  f: F,
  id: string,
  actions: Action[],
  navigate: (pathname: string) => void,
  navigateBack: () => void,
  openDialog: (dialog: string) => void,
  closeDialog: () => void,
  closeAllDialogs: () => void,
  screens: Screen[],
  screenConfig: Screen,
  getVariableValue: GetVariableValue,
  getVariable: GetVariable,
  update: Update,
  indexInList?: number
) => {
  const {
    auth,
    signInWithGoogle,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    sendSignInLinkToEmail,
    reSendSignInLinkToEmail,
    waitProfile,
    checkNotificationsEnabled,
    enableNotifications,
    logout,
    runFlow,
    oauth,
  } = f;
  try {
    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];
      const {
        actionType,
        options,
        componentName,
        parameters,
        variable,
        variableContextKey,
        subActions,
        fallbackActions,
        asynchronous,
      } = action;
      const email = options?.email;
      const screen = options?.screen;
      const name = options?.name;
      const link = options?.link;
      if (
        (actionType === ActionType.check &&
          (await getVariableValue(variable)) === false) ||
        (actionType === ActionType.checkNotificationsEnabled &&
          (await checkNotificationsEnabled()) === false) ||
        (actionType === ActionType.checkIdfaAndCookieTrackingEnabled &&
          checkIdfaAndCookieTrackingEnabled() === false)
      ) {
        if (fallbackActions) {
          await runActions(
            f,
            id,
            fallbackActions,
            navigate,
            navigateBack,
            openDialog,
            closeDialog,
            closeAllDialogs,
            screens,
            screenConfig,
            getVariableValue,
            getVariable,
            update,
            indexInList
          );
        }
        break;
      } else if (actionType === ActionType.handleDeeplink) {
        const { pathname } = window.location;
        if (
          pathname !== "/" &&
          pathname !== `/${slugify(screenConfig.screenName)}`
        ) {
          navigate(pathname);
          return true;
        } else {
          if (fallbackActions) {
            await runActions(
              f,
              id,
              fallbackActions,
              navigate,
              navigateBack,
              openDialog,
              closeDialog,
              closeAllDialogs,
              screens,
              screenConfig,
              getVariableValue,
              getVariable,
              update,
              indexInList
            );
          }
        }
      } else if (actionType === ActionType.subscribe) {
        const refresh = async () => {
          const value = await getVariableValue(
            variable,
            {
              [generateRefreshKey(
                id,
                variableContextKey + "variable",
                indexInList
              )]: refresh,
            },
            undefined,
            true
          );
          await getVariableValue(
            {
              source: {
                type: VariableSourceType.localVariable,
                variableName: variableContextKey,
              },
            },
            undefined,
            { value },
            true
          );
          if (subActions) {
            await runActions(
              f,
              id,
              subActions,
              navigate,
              navigateBack,
              openDialog,
              closeDialog,
              closeAllDialogs,
              screens,
              screenConfig,
              getVariableValue,
              getVariable,
              update,
              indexInList
            );
          }
        };
        await refresh();
      } else if (
        actionType === ActionType.checkAccessLevels &&
        screen &&
        parameters
      ) {
        const { levels = [], requiredLevels = [] } = await getParams(
          parameters,
          getVariableValue
        );
        if (!requiredLevels.every((el: string) => levels.includes(el))) {
          openDialog(slugify(screen));
          return true;
        }
      } else if (actionType === ActionType.show && screen) {
        navigate(`/${slugify(screen)}`);
        return true;
      } else if (actionType === ActionType.navigate && screen) {
        if (parameters) {
          const screenConfig = screens.find((el) => el.screenName === screen);
          if (screenConfig) {
            const { inputParameters } = screenConfig;
            const { inputParameter } = getInputParameters(inputParameters);
            if (inputParameter) {
              const parameter = parameters.find(
                (el) => inputParameter.parameter === el.name
              );
              if (parameter) {
                const value = await getVariableValue(parameter.value);
                navigate(`-/${slugify(screen)}/${encodeURIComponent(value)}`);
                return true;
              }
            }
          }
        } else {
          navigate(`-/${slugify(screen)}`);
          return true;
        }
      } else if (actionType === ActionType.navigateBack) {
        navigateBack();
        return true;
      } else if (actionType === ActionType.openDialog && screen) {
        if (parameters) {
          const screenConfig = screens.find((el) => el.screenName === screen);
          if (screenConfig) {
            const { inputParameters } = screenConfig;
            const { inputParameter, inoutInputParameters } =
              getInputParameters(inputParameters);
            if (inputParameter) {
              const parameter = parameters.find(
                (el) => inputParameter.parameter === el.name
              );
              if (parameter) {
                const value = await getVariableValue(parameter.value);
                await setInputParameters(
                  screenConfig.id,
                  inputParameters as ScreenParameter[],
                  getVariable(screenConfig),
                  value
                );
              }
            }
            if (inoutInputParameters) {
              const inoutParameters = parameters.filter(
                (a) =>
                  !!inoutInputParameters.find((b) => a.name === b.parameter)
              );
              for (const el of inoutParameters) {
                const variable = {
                  source: {
                    type: VariableSourceType.localVariable,
                    variableName: el.name,
                  },
                };
                const value = await getVariableValue(
                  el.value,
                  undefined,
                  undefined,
                  true
                );
                await getVariable(screenConfig)(
                  variable,
                  undefined,
                  { value },
                  true
                );
                const refresh = async () => {
                  const value = await getVariable(screenConfig)(
                    variable,
                    {
                      [generateRefreshKey(
                        id,
                        el.name + "variable",
                        indexInList
                      )]: refresh,
                    },
                    undefined,
                    true
                  );
                  await getVariableValue(el.value, undefined, { value }, true);
                };
                await refresh();
              }
            }
          }
        }
        openDialog(slugify(screen));
      } else if (actionType === ActionType.closeDialog) {
        closeDialog();
      } else if (actionType === ActionType.closeAllDialogs) {
        closeAllDialogs();
      } else if (
        actionType === ActionType.getImage ||
        actionType === ActionType.getVideo ||
        actionType === ActionType.getAudio ||
        actionType === ActionType.getFile ||
        actionType === ActionType.setValue ||
        actionType === ActionType.clearValue ||
        actionType === ActionType.createRecord ||
        actionType === ActionType.updateRecord ||
        actionType === ActionType.deleteRecord
      ) {
        await update(getVariableValue, action);
      } else if (actionType === ActionType.logout) {
        await logout();
      } else if (actionType === ActionType.anonymousAuthentication) {
        await auth.signInAnonymously();
      } else if (actionType === ActionType.googleAuthentication) {
        await signInWithGoogle();
        await waitProfile(getVariableValue);
      } else if (
        actionType === ActionType.createUserWithEmailAndPassword &&
        parameters
      ) {
        await createUserWithEmailAndPassword(parameters, getVariableValue);
        await waitProfile(getVariableValue);
      } else if (
        actionType === ActionType.signInWithEmailAndPassword &&
        parameters
      ) {
        await signInWithEmailAndPassword(parameters, getVariableValue);
        await waitProfile(getVariableValue);
      } else if (actionType === ActionType.sendPasswordResetEmail && variable) {
        await sendPasswordResetEmail(variable, getVariableValue);
      } else if (actionType === ActionType.sendSignInLinkToEmail && variable) {
        await sendSignInLinkToEmail(variable, getVariableValue);
      } else if (
        actionType === ActionType.reSendSignInLinkToEmail &&
        variable
      ) {
        await reSendSignInLinkToEmail(variable, getVariableValue);
      } else if (actionType === ActionType.runFlow && name && parameters) {
        if (asynchronous) {
          runFlow(`${name}?async=true`, parameters, getVariableValue).catch(
            (err) => alert(err)
          );
        } else {
          await runFlow(name, parameters, getVariableValue);
        }
      } else if (actionType === ActionType.oauth && parameters) {
        await oauth(parameters, getVariableValue);
      } else if (actionType === ActionType.enableNotifications) {
        await enableNotifications();
      } else if (actionType === ActionType.enableIdfaAndCookieTracking) {
        enableIdfaAndCookieTracking();
      } else if (
        actionType === ActionType.openLink ||
        actionType === ActionType.openWeb
      ) {
        window.open(
          await getVariableValue({ ...link, urlConstant: link?.constant })
        );
      } else if (actionType === ActionType.openEmail && email) {
        window.location.href = `mailto:${options.email}`;
      } else if (actionType === ActionType.checkoutSelected && variable) {
        await checkoutSelected(auth, variable, getVariableValue);
      } else if (
        (actionType === ActionType.scrollToStart ||
          actionType === ActionType.scrollToEnd) &&
        componentName
      ) {
        const component = findComponent(screenConfig, "name", componentName);
        if (component) {
          const { reversed } = component;
          const reversedValue = await getVariableValue({
            ...reversed,
            booleanConstant: reversed?.constant,
          });
          const elementToScroll = document.querySelector(
            `[id*=".${componentName}."]`
          );
          if (elementToScroll) {
            elementToScroll.scrollLeft =
              actionType === ActionType.scrollToStart
                ? !reversedValue
                  ? 0
                  : elementToScroll.scrollWidth
                : reversedValue
                ? 0
                : elementToScroll.scrollWidth;
            elementToScroll.scrollTop =
              actionType === ActionType.scrollToStart
                ? !reversedValue
                  ? 0
                  : elementToScroll.scrollHeight
                : reversedValue
                ? 0
                : elementToScroll.scrollHeight;
          }
        }
      }
    }
  } catch (err: any) {
    if (err) {
      alert(readableError(err.message));
    }
  }
};
