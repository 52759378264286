import firebase from "firebase/compat/app";

import {
  CollectionProperty,
  TableQueryFilterOperator,
  ValueType,
} from "./index";

export const operators: {
  [key in TableQueryFilterOperator]: firebase.firestore.WhereFilterOp;
} = {
  [TableQueryFilterOperator.equal]: "==",
  [TableQueryFilterOperator.notEqual]: "!=",
  [TableQueryFilterOperator.less]: "<",
  [TableQueryFilterOperator.lessOrEqual]: "<=",
  [TableQueryFilterOperator.greater]: ">",
  [TableQueryFilterOperator.greaterOrEqual]: ">=",
  [TableQueryFilterOperator.contains]: "array-contains",
  [TableQueryFilterOperator.matches]: "==",
  [TableQueryFilterOperator.in]: "in",
  [TableQueryFilterOperator.notIn]: "not-in",
};

export const needOrderByOperators = [
  operators[TableQueryFilterOperator.notEqual],
  operators[TableQueryFilterOperator.less],
  operators[TableQueryFilterOperator.lessOrEqual],
  operators[TableQueryFilterOperator.greater],
  operators[TableQueryFilterOperator.greaterOrEqual],
];

export const queryOperators: {
  [key in firebase.firestore.WhereFilterOp]: string;
} = {
  "<": "LESS_THAN",
  "<=": "LESS_THAN_OR_EQUAL",
  "==": "EQUAL",
  "!=": "NOT_EQUAL",
  ">=": "GREATER_THAN_OR_EQUAL",
  ">": "GREATER_THAN",
  "array-contains": "ARRAY_CONTAINS",
  "array-contains-any": "ARRAY_CONTAINS_ANY",
  in: "IN",
  "not-in": "NOT_IN",
};

export const getValueType = (
  properties: CollectionProperty[],
  fieldPath: string
) => {
  const property = properties.find((el) => el.name === fieldPath);
  switch (property?.type) {
    case ValueType.vector:
      return "vectorSourceValue";
    case ValueType.boolean:
      return "booleanValue";
    case ValueType.number:
      return "doubleValue";
    case ValueType.date:
    case ValueType.dateTime:
      return "timestampValue";
    default:
      return "stringValue";
  }
};
