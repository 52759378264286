import { AxiosResponse } from "axios";
import firebase from "firebase/compat/app";
import { RemoteConfig } from "firebase/remote-config";
import { createContext } from "react";

import { GetVariableValue } from "../hooks";
import { AnyVariable, InputParameter } from "../utils";

export type FP = (
  parameters: InputParameter[],
  getVariableValue: GetVariableValue
) => Promise<void>;

export type FV = (
  variable: AnyVariable,
  getVariableValue: GetVariableValue
) => Promise<void>;

export type FNP = (
  name: string,
  parameters: InputParameter[],
  getVariableValue: GetVariableValue
) => Promise<AxiosResponse<any, any>>;

export interface F {
  firestorePrefix: string;
  auth: firebase.auth.Auth;
  firestore: firebase.firestore.Firestore;
  remoteConfig: RemoteConfig;
  signInWithGoogle: () => Promise<void>;
  createUserWithEmailAndPassword: FP;
  signInWithEmailAndPassword: FP;
  sendPasswordResetEmail: FV;
  sendSignInLinkToEmail: FV;
  reSendSignInLinkToEmail: FV;
  waitProfile: (getVariableValue: GetVariableValue) => Promise<void>;
  getAssetRecord: (file: any) => Promise<unknown>;
  checkNotificationsEnabled: () => Promise<boolean>;
  enableNotifications: () => Promise<void>;
  logout: () => Promise<void>;
  runFlow: FNP;
  search: (url: string) => Promise<AxiosResponse<any, any>>;
  oauth: FP;
  authHandler: (data: any) => Promise<AxiosResponse<any, any>>;
  emailKey: string;
  oauthStateKey: string;
}

export const FirebaseContext = createContext({
  f: {
    firestorePrefix: "",
    auth: {},
    firestore: {},
    remoteConfig: {},
    signInWithGoogle: () => {},
    createUserWithEmailAndPassword: (parameters, getVariableValue) => {},
    signInWithEmailAndPassword: (parameters, getVariableValue) => {},
    sendPasswordResetEmail: (variable, getVariableValue) => {},
    sendSignInLinkToEmail: (variable, getVariableValue) => {},
    reSendSignInLinkToEmail: (variable, getVariableValue) => {},
    waitProfile: (getVariableValue) => {},
    getAssetRecord: (file) => {},
    checkNotificationsEnabled: () => {},
    enableNotifications: () => {},
    logout: () => {},
    runFlow: (name, parameters, getVariableValue) => {},
    search: (url: string) => {},
    oauth: (parameters, getVariableValue) => {},
    authHandler: (data) => {},
    emailKey: "",
    oauthStateKey: "",
  } as F,
});

export * from "./FirebaseProvider";
