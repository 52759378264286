const audioPlayer = document.createElement("audio");

export const onPlayElementClick = (
  src: string,
  playElement: HTMLDivElement,
  onPrimary: string,
  id: string,
  indexInList?: number
) => {
  if (audioPlayer.id !== id + indexInList) {
    audioPlayer.pause();
    audioPlayer.id = id + indexInList;
    audioPlayer.src = src;
  }
  setTimeout(() => {
    if (audioPlayer.paused) {
      audioPlayer.play();
      playElement.innerHTML = `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M14 19H18V5H14M6 19H10V5H6V19Z" fill="${onPrimary}"/>
      </svg>
      `;
      audioPlayer.onpause = () => {
        playElement.innerHTML = `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M8 5.14001V19.14L19 12.14L8 5.14001Z" fill="${onPrimary}"/>
        </svg>
        `;
      };
    } else {
      audioPlayer.pause();
    }
  }, 0);
};

export const pauseAudioPlayerIfNoSrc = (id: string, indexInList?: number) => {
  if (audioPlayer.id === id + indexInList) {
    audioPlayer.pause();
    audioPlayer.removeAttribute("id");
    audioPlayer.removeAttribute("src");
  }
};
