import { generateRefreshKey, GetVariableValue } from "../hooks";
import {
  BooleanVariable,
  NumberVariable,
  progressActiveAttribute,
  progressTrackAttribute,
  progressPassedAttribute,
} from "./index";

const nextEvent = "nextEvent";
export const progressIndicatorHandler = async (
  id: string,
  progressIndicator: HTMLElement,
  getVariableValue: GetVariableValue,
  value?: NumberVariable,
  duration?: NumberVariable,
  loop?: BooleanVariable,
  indexInList?: number
) => {
  const progressTracks = Array.from(
    progressIndicator.querySelectorAll(
      `:scope > i > i > i[${progressTrackAttribute}]`
    )
  ) as SVGRectElement[];
  if (progressTracks.length) {
    const lastIndex = progressTracks.length - 1;
    const loopValue = await getVariableValue({
      ...loop,
      booleanConstant: loop?.constant,
    });
    const durationValue = await getVariableValue({
      ...duration,
      numberConstant: duration?.constant,
    });
    progressTracks.forEach((el, i) =>
      el.addEventListener(nextEvent, () => {
        progressTracks.slice(0, i).forEach((el) => {
          el.setAttribute(progressPassedAttribute, "true");
          el.removeAttribute(progressActiveAttribute);
        });
        progressTracks.slice(i + 1).forEach((el) => {
          el.removeAttribute(progressPassedAttribute);
          el.removeAttribute(progressActiveAttribute);
        });
        progressTracks[i].removeAttribute(progressPassedAttribute);
        progressTracks[i].setAttribute(progressActiveAttribute, "true");
        const next = () => {
          if (progressTracks[i + 1]) {
            progressTracks[i + 1].dispatchEvent(new Event(nextEvent));
          } else if (loopValue) {
            progressTracks[0].dispatchEvent(new Event(nextEvent));
          }
        };
        if (!document.getElementById(progressIndicator.id)) {
          return;
        } else if (durationValue) {
          setTimeout(next, durationValue * 1000);
        }
      })
    );
    const valueRefresh = async () => {
      progressTracks.forEach((el) => {
        el.removeAttribute(progressPassedAttribute);
        el.removeAttribute(progressActiveAttribute);
      });
      const valueValue = await getVariableValue(
        { ...value, numberConstant: value?.constant },
        { [generateRefreshKey(id, "value", indexInList)]: valueRefresh }
      );
      const currentValue = (valueValue || 0) - 1;
      progressTracks[
        currentValue > lastIndex ? -1 : currentValue
      ]?.dispatchEvent(new Event(nextEvent));
    };
    await valueRefresh();
  }
};
