import { useEffect, useState } from "react";

import { App } from "./App";
import codeplatformJson from "./codeplatform.json";
import { FirebaseConfig, FirebaseProvider } from "./context";
// import { useVersionController } from "./hooks";
import {
  Config,
  getLanguageFromStorage,
  getThemeFromStorage,
  languageKey,
  themeKey,
} from "./utils";

const inIframe = window.location !== window.parent.location;
const senderOrigin = inIframe && document.referrer.slice(0, -1);
const waitForMessage =
  senderOrigin &&
  (senderOrigin.startsWith("http://localhost") ||
    senderOrigin.endsWith("codeplatform.com"));

export const AppWrapper = () => {
  const [wait, setWait] = useState(waitForMessage);
  const [inDevice, setInDevice] = useState(false);
  const [firestorePrefix, setFirestorePrefix] = useState<string>();
  const [webFirebaseConfig, setWebFirebaseConfig] = useState<FirebaseConfig>();
  const [config, setConfig] = useState(codeplatformJson as Config);
  const [language, setLanguage] = useState(getLanguageFromStorage());
  const [theme, setTheme] = useState(getThemeFromStorage());
  const [email, setEmail] = useState<string>();
  const [token, setToken] = useState<string>();
  // useVersionController();

  useEffect(() => {
    localStorage.setItem(languageKey, language);
  }, [language]);

  useEffect(() => {
    localStorage.setItem(themeKey, theme);
  }, [theme]);

  useEffect(() => {
    if (wait) {
      const onMessage = ({ data }: any) => {
        if (data && typeof data === "object") {
          const {
            inDevice,
            firestorePrefix,
            webFirebaseConfig,
            config,
            language,
            theme,
            name,
            icon,
            customColors,
            email,
            token,
          } = data;
          if (
            inDevice !== undefined &&
            webFirebaseConfig &&
            config &&
            language &&
            theme
          ) {
            setInDevice(inDevice);
            setFirestorePrefix(firestorePrefix);
            setWebFirebaseConfig(webFirebaseConfig);
            setConfig(config);
            setLanguage(language);
            setTheme(theme);
            setEmail(email);
            setToken(token);
            setWait(false);
          } else if (inDevice !== undefined || name || icon || customColors) {
            if (inDevice !== undefined) {
              setInDevice(inDevice);
            }
            if (name || icon || customColors) {
              const config = JSON.parse(
                JSON.stringify(codeplatformJson)
              ) as Config;
              if (name) {
                config.branding.appName.locales.en = name;
              }
              if (icon) {
                config.branding.icons.androidIcon = icon.id;
                config.branding.icons.iosIcon = icon.id;
                try {
                  // @ts-ignore
                  config.screens.find(
                    (el) => el.screenName === "Launch Screen"
                  ).components[0].subComponents[0].image.constant.resourceId =
                    icon.id;
                } catch {}
                config.resources = [...(config.resources || []), icon];
              }
              if (customColors) {
                config.branding.colorStyles = {
                  ...config.branding.colorStyles,
                  ...customColors,
                };
              }
              setConfig(config);
            }
            setWait(false);
          }
        }
      };
      window.addEventListener("message", onMessage);
      window.parent.postMessage("READY", "*");
      return () => {
        window.removeEventListener("message", onMessage);
      };
    }
  }, []);

  return wait ? (
    <></>
  ) : (
    <FirebaseProvider
      webFirebaseConfig={webFirebaseConfig}
      firestorePrefix={firestorePrefix}
    >
      <App
        inDevice={inDevice}
        language={language}
        setLanguage={setLanguage}
        theme={theme}
        setTheme={setTheme}
        config={config}
        email={email}
        token={token}
      />
    </FirebaseProvider>
  );
};
