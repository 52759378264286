import { GetVariableValue } from "../hooks";
import {
  BrandingColorType,
  getMediaResourceUrl,
  VariableSourceType,
} from "./index";

export const createLogo = async (
  screen: HTMLElement,
  fontFamily: string,
  hasText: boolean,
  getVariableValue: GetVariableValue,
  goHome?: () => void
) => {
  const logo = document.createElement("div");
  logo.id = "logo";
  screen.appendChild(logo);

  const imageElement = document.createElement("img");
  imageElement.setAttribute(
    "src",
    await getMediaResourceUrl(
      getVariableValue,
      undefined,
      {
        source: {
          type: VariableSourceType.globalVariable,
          variableName: "applicationIcon",
        },
      },
      undefined,
      imageElement
    )
  );
  logo.appendChild(imageElement);

  if (hasText) {
    const onBackground = await getVariableValue({
      colorConstant: `@${BrandingColorType.onBackground}`,
    });
    const textElement = document.createElement("div");
    textElement.style.color = onBackground;
    textElement.style.fontFamily = fontFamily;
    textElement.innerHTML = await getVariableValue({
      source: {
        type: VariableSourceType.globalVariable,
        variableName: "applicationName",
      },
    });
    logo.appendChild(textElement);
  }

  if (goHome) {
    logo.className = "clickable";
    logo.onclick = () => goHome();
  }
};
