import { F } from "../context";
import {
  GetVariable,
  GetVariableValue,
  Update,
  generateRefreshKey,
  setLocalVariables,
} from "../hooks";
import {
  BrandingFontStyle,
  BrandingThemeType,
  ComponentAlignment,
  ComponentType,
  ComponentsDirection,
  ListStyle,
  Screen,
  ScreenComponent,
  createAudio,
  createButton,
  createCalendar,
  createCarousel,
  createImage,
  createList,
  createMap,
  createNotificationsSettings,
  createOffer,
  createProgressIndicator,
  createSlider,
  createSpacer,
  createText,
  createTextInput,
  createThemePicker,
  createToggle,
  createVideo,
  getCSSTextAlignment,
  getComponentTypeById,
  getElementIdFromConfig,
  isParentComponent,
  listEmptyName,
  listGalleryHandler,
  runActions,
  setColors,
  setListArrows,
  startLoader,
  stopLoader,
} from "./index";

export const createElement = async (
  f: F,
  setSubComponents: (element: HTMLElement, component: ScreenComponent) => void,
  component: ScreenComponent,
  parent: HTMLElement,
  fontFamily: string,
  fontStyles: BrandingFontStyle[],
  theme: BrandingThemeType,
  customScroll: boolean,
  firstChildOfScreen: boolean,
  onlyChild: boolean,
  desktopMode: boolean,
  navigate: (pathname: string) => void,
  navigateBack: () => void,
  openDialog: (dialog: string) => void,
  closeDialog: () => void,
  closeAllDialogs: () => void,
  screens: Screen[],
  screenConfig: Screen,
  getVariableValue: GetVariableValue,
  getVariable: GetVariable,
  update: Update,
  setScroll: (func: () => void) => void,
  limit: number,
  setLimit: (limit: number) => void
) => {
  const parentComponentType = getComponentTypeById(parent.id);
  const {
    id,
    indexInList,
    visible,
    name,
    backgroundColor,
    borderColor,
    borderWidth,
    cornersRadius,
    font,
    textColor,
    textAlignment,
    componentsDirection,
    reversed,
    contentPadding,
    componentType,
    componentsSpacing,
    margins,
    width,
    height,
    componentAlignment,
    backgroundGradient,
    backgroundPattern,
    widthHeightRatio,
    localVariables,
    actions,
    enabled,
    listStyle,
    value,
  } = component;

  const element = document.createElement(
    componentType === ComponentType.textInput ||
      componentType === ComponentType.datePicker
      ? "form"
      : "div"
  );
  parent.appendChild(element);

  const elementId = getElementIdFromConfig(component);
  element.id = elementId;
  element.style.position = "relative";
  element.style.fontFamily = fontFamily;
  element.style.boxSizing = "border-box";

  if (localVariables) {
    await setLocalVariables(id, getVariableValue, localVariables, indexInList);
  }

  if (visible) {
    const visibleRefresh = async () => {
      const notVisibleValue =
        (await getVariableValue(
          { ...visible, booleanConstant: visible.constant },
          { [generateRefreshKey(id, "visible", indexInList)]: visibleRefresh }
        )) === false;
      if (notVisibleValue) {
        element.hidden = true;
      } else {
        element.removeAttribute("hidden");
      }
    };
    await visibleRefresh();
  }

  if (enabled) {
    const enabledRefresh = async () => {
      const notEnabledValue =
        (await getVariableValue(
          { ...enabled, booleanConstant: enabled.constant },
          { [generateRefreshKey(id, "enabled", indexInList)]: enabledRefresh }
        )) === false;
      if (notEnabledValue) {
        element.style.pointerEvents = "none";
      } else {
        element.style.removeProperty("pointer-events");
      }
    };
    await enabledRefresh();
  }

  if (customScroll) {
    window.onscroll = () => {
      const scrollElement = document.getElementById(elementId);
      if (scrollElement) {
        const scrollTop = window.scrollY;
        setScroll(() => window.scrollTo({ top: scrollTop }));
      }
    };
  } else if (
    (firstChildOfScreen &&
      (componentType === ComponentType.scroll ||
        componentType === ComponentType.list)) ||
    (name === "Messages" && componentType === ComponentType.list)
  ) {
    element.onscroll = () => {
      const scrollTop = element.scrollTop;
      setScroll(() => {
        const scrollElement = document.getElementById(elementId);
        if (scrollElement) {
          scrollElement.scrollTop = scrollTop;
        }
      });
    };
  }

  if (
    componentType === ComponentType.textInput ||
    componentType === ComponentType.datePicker
  ) {
    element.onsubmit = (e) => e.preventDefault();
  }

  if (actions) {
    if (
      componentType !== ComponentType.textInput &&
      componentType !== ComponentType.datePicker
    ) {
      element.className = "clickable";
    }
    element[
      componentType === ComponentType.textInput ||
      componentType === ComponentType.datePicker
        ? "onsubmit"
        : "onclick"
    ] = async (e) => {
      e.preventDefault();
      startLoader();
      element.setAttribute("loading", "true");
      await runActions(
        f,
        id,
        actions,
        navigate,
        navigateBack,
        openDialog,
        closeDialog,
        closeAllDialogs,
        screens,
        screenConfig,
        getVariableValue,
        getVariable,
        update,
        indexInList
      );
      element.removeAttribute("loading");
      stopLoader();
    };
  }

  if (parentComponentType === ComponentType.overlay) {
    switch (componentAlignment) {
      case ComponentAlignment.fill:
        element.style.position = "absolute";
        element.style.top = "0";
        element.style.left = "0";
        element.style.bottom = "0";
        element.style.right = "0";
        break;
      case ComponentAlignment.leading:
        element.style.position = "absolute";
        element.style.left = "0";
        break;
      case ComponentAlignment.trailing:
        element.style.position = "absolute";
        element.style.right = "0";
        break;
      case ComponentAlignment.top:
        element.style.position = "absolute";
        element.style.top = "0";
        element.style.left = "0";
        element.style.right = "0";
        break;
      case ComponentAlignment.topLeading:
        element.style.position = "absolute";
        element.style.top = "0";
        element.style.left = "0";
        break;
      case ComponentAlignment.topCenter:
        element.style.position = "absolute";
        element.style.top = "0";
        element.style.left = "50%";
        element.style.transform = "translateX(-50%)";
        break;
      case ComponentAlignment.topTrailing:
        element.style.position = "absolute";
        element.style.top = "0";
        element.style.right = "0";
        break;
      case ComponentAlignment.centerLeading:
        element.style.position = "absolute";
        element.style.top = "50%";
        element.style.left = "0";
        element.style.transform = "translateY(-50%)";
        break;
      case ComponentAlignment.center:
        element.style.position = "absolute";
        element.style.top = "50%";
        element.style.left = "50%";
        element.style.transform = "translate(-50%, -50%)";
        break;
      case ComponentAlignment.centerTrailing:
        element.style.position = "absolute";
        element.style.top = "50%";
        element.style.right = "0";
        element.style.transform = "translateY(-50%)";
        break;
      case ComponentAlignment.bottom:
        element.style.position = "absolute";
        element.style.bottom = "0";
        element.style.left = "0";
        element.style.right = "0";
        break;
      case ComponentAlignment.bottomLeading:
        element.style.position = "absolute";
        element.style.bottom = "0";
        element.style.left = "0";
        break;
      case ComponentAlignment.bottomCenter:
        element.style.position = "absolute";
        element.style.bottom = "0";
        element.style.left = "50%";
        element.style.transform = "translateX(-50%)";
        break;
      case ComponentAlignment.bottomTrailing:
        element.style.position = "absolute";
        element.style.bottom = "0";
        element.style.right = "0";
        break;
    }
  } else {
    switch (componentAlignment) {
      case ComponentAlignment.fill:
        if (
          (parentComponentType !== ComponentType.scroll &&
            parentComponentType !== ComponentType.list &&
            onlyChild &&
            isParentComponent(componentType)) ||
          name === listEmptyName ||
          componentType === ComponentType.map
        ) {
          element.style.width = `calc(100% - (${
            (await getVariableValue({
              numberConstant: margins?.leading,
            })) || 0
          }px + ${
            (await getVariableValue({
              numberConstant: margins?.trailing,
            })) || 0
          }px))`;
          element.style.height = `calc(100% - (${
            (await getVariableValue({
              numberConstant: margins?.top,
            })) || 0
          }px + ${
            (await getVariableValue({
              numberConstant: margins?.bottom,
            })) || 0
          }px))`;
        }
        element.style.alignSelf = "stretch";
        break;
      case ComponentAlignment.start:
        element.style.alignSelf = "flex-start";
        break;
      case ComponentAlignment.end:
        element.style.alignSelf = "flex-end";
        break;
      case ComponentAlignment.center:
        element.style.alignSelf = "center";
        break;
    }
  }

  if (width) {
    const widthValue = await getVariableValue({
      ...width,
      numberConstant: width?.constant,
    });
    element.style.width = `${widthValue}px`;
    if (parent.style.flexDirection === "row") {
      element.style.flexShrink = "0";
    }
  }

  if (height) {
    const heightValue = await getVariableValue({
      ...height,
      numberConstant: height?.constant,
    });
    element.style.height = `${heightValue}px`;
    if (parent.style.flexDirection === "column") {
      element.style.flexShrink = "0";
    }
  }

  if (widthHeightRatio) {
    const widthHeightRatioValue = await getVariableValue({
      ...widthHeightRatio,
      numberConstant: widthHeightRatio?.constant,
    });
    const { width, height } = element.getBoundingClientRect();
    if (!height && width) {
      const heightValue = width / widthHeightRatioValue;
      element.style.height = `${heightValue}px`;
    }
    if (!width && height) {
      const widthValue = height * widthHeightRatioValue;
      element.style.width = `${widthValue}px`;
    }
    element.style.flexShrink = "0";
  }

  if (
    !width &&
    !widthHeightRatio &&
    parentComponentType === ComponentType.stack &&
    parent.style.flexDirection === "row" &&
    componentType !== ComponentType.image
  ) {
    element.style.flexGrow = "1";
  }

  if (componentType === ComponentType.text) {
    await createText(element, component, getVariableValue);
  }

  if (componentType === ComponentType.button) {
    await createButton(element, component, getVariableValue);
  }

  if (componentType === ComponentType.image) {
    await createImage(element, component, getVariableValue);
  }

  if (componentType === ComponentType.videoPlayer) {
    await createVideo(element, component, getVariableValue);
  }

  if (componentType === ComponentType.audioPlayer) {
    await createAudio(element, component, fontStyles, getVariableValue);
  }

  if (componentType === ComponentType.themePicker) {
    await createThemePicker(element, getVariableValue, theme);
  }

  if (componentType === ComponentType.notificationsSettings) {
    await createNotificationsSettings(element, getVariableValue);
  }

  if (componentType === ComponentType.carousel) {
    await createCarousel(element, component, getVariableValue);
  }

  if (componentType === ComponentType.view) {
    if (!height && !width && !widthHeightRatio) {
      createSpacer(element, false);
    }
  }

  if (componentType === ComponentType.spacer) {
    createSpacer(element, !!(height || width));
  }

  if (
    componentType === ComponentType.scroll ||
    componentType === ComponentType.list
  ) {
    if (componentsDirection !== ComponentsDirection.horizontal) {
      element.style.flexGrow = "1";
    }
    element.style.overflow =
      componentsDirection === ComponentsDirection.all
        ? "auto"
        : componentsDirection === ComponentsDirection.vertical
        ? "hidden auto"
        : "auto hidden";
  }

  if (componentType === ComponentType.map) {
    await createMap(
      element,
      component,
      screenConfig,
      getVariable,
      getVariableValue
    );
  }

  if (componentType === ComponentType.calendar) {
    await createCalendar(
      element,
      component,
      fontStyles,
      screenConfig,
      getVariable,
      getVariableValue
    );
  }

  if (componentType === ComponentType.slider) {
    await createSlider(element, component, getVariableValue);
  }

  if (componentType === ComponentType.toggle) {
    await createToggle(element, component, getVariableValue);
  }

  if (componentType === ComponentType.progressIndicator) {
    await createProgressIndicator(element, component, getVariableValue);
  }

  if (componentsDirection) {
    if (componentsDirection === ComponentsDirection.all) {
      const componentsSpacingValue = await getVariableValue({
        ...componentsSpacing,
        numberConstant: componentsSpacing?.constant,
      });
      element.style.display = "grid";
      const columnWidth = `calc(50% - ${componentsSpacingValue / 2}px)`;
      element.style.gridTemplateColumns = `${columnWidth} ${columnWidth}`;
    } else {
      const reversedValue = await getVariableValue({
        ...reversed,
        booleanConstant: reversed?.constant,
      });
      element.style.display = "flex";
      element.style.flexDirection = `${
        componentsDirection === ComponentsDirection.vertical ? "column" : "row"
      }${reversedValue ? "-reverse" : ""}`;
    }
  }

  if (textAlignment) {
    element.style.textAlign = getCSSTextAlignment(textAlignment);
  }

  const currentFont = fontStyles.find(
    (el) => el.styleName === font?.slice(1)
  )?.ios;
  if (currentFont) {
    element.style.fontSize = `${currentFont.fontSize}px`;
    element.style.fontWeight = `${currentFont.fontWeight}`;
    element.style.fontStyle = currentFont.fontStyle;
    element.style.letterSpacing = `${currentFont.letterSpacing}px`;
    element.style.lineHeight = `${currentFont.lineHeight}%`;
  }

  await setColors(
    id,
    element,
    getVariableValue,
    backgroundColor,
    backgroundGradient,
    backgroundPattern,
    textColor,
    borderColor,
    indexInList
  );

  if (borderWidth) {
    const borderWidthValue = await getVariableValue({
      ...borderWidth,
      numberConstant: borderWidth?.constant,
    });
    element.style.borderWidth = `${borderWidthValue}px`;
    element.style.borderStyle = "solid";
  }

  if (contentPadding) {
    const { top, trailing, bottom, leading } = contentPadding.constant || {};
    element.style.padding = `${
      (await getVariableValue({ numberConstant: top })) || 0
    }px ${(await getVariableValue({ numberConstant: trailing })) || 0}px ${
      (await getVariableValue({ numberConstant: bottom })) || 0
    }px ${(await getVariableValue({ numberConstant: leading })) || 0}px`;
  }

  if (margins) {
    const { top, trailing, bottom, leading } = margins;
    element.style.margin = `${
      (await getVariableValue({ numberConstant: top })) || 0
    }px ${(await getVariableValue({ numberConstant: trailing })) || 0}px ${
      (await getVariableValue({ numberConstant: bottom })) || 0
    }px ${(await getVariableValue({ numberConstant: leading })) || 0}px`;
  }

  if (componentsSpacing) {
    const componentsSpacingValue = await getVariableValue({
      ...componentsSpacing,
      numberConstant: componentsSpacing?.constant,
    });
    element.style.gap = `${componentsSpacingValue}px`;
  }

  if (cornersRadius) {
    if (
      isParentComponent(componentType) ||
      componentType === ComponentType.map
    ) {
      element.style.overflow = "hidden";
      if (
        parentComponentType === ComponentType.scroll ||
        parentComponentType === ComponentType.list ||
        parent.style.flexDirection === "column"
      ) {
        element.style.flexShrink = "0";
      }
    }
    const cornersRadiusValue = await getVariableValue({
      ...cornersRadius,
      numberConstant: cornersRadius?.constant,
    });
    element.style.borderRadius = `${cornersRadiusValue}px`;
  }

  if (
    componentType === ComponentType.textInput ||
    componentType === ComponentType.datePicker
  ) {
    await createTextInput(
      element,
      component,
      fontStyles,
      componentType === ComponentType.datePicker,
      getVariableValue
    );
  }

  if (componentType === ComponentType.offer) {
    await createOffer(element, component, fontStyles, getVariableValue);
  }

  if (componentType === ComponentType.list) {
    if (desktopMode && componentsDirection === ComponentsDirection.horizontal) {
      await setListArrows(element, getVariableValue, reversed);
    }
    await createList(
      element,
      component,
      screenConfig,
      getVariable,
      getVariableValue,
      setSubComponents,
      limit,
      setLimit
    );
    if (listStyle === ListStyle.gallery) {
      await listGalleryHandler(
        id,
        element,
        getVariableValue,
        value,
        reversed,
        name,
        indexInList
      );
    }
  } else {
    await setSubComponents(element, component);
  }
};
