import firebase from "firebase/compat/app";
import { marked } from "marked";
import { useEffect } from "react";
import { format } from "timeago.js";

import { F } from "../context";
import {
  Action,
  ActionType,
  AnyVariable,
  BrandingColorType,
  BrandingThemeType,
  CMSCollectionRecord,
  Collection,
  CollectionProperty,
  CollectionVariable,
  Config,
  DateVariable,
  InputParameter,
  LocalVariable,
  LocalizedContent,
  NumberVariable,
  RecordModification,
  Screen,
  ScreenComponent,
  ScreenParameter,
  TableQuery,
  TableQueryFilterOperator,
  TableQueryOrder,
  ValueType,
  VariableSource,
  VariableSourceType,
  VariableTransform,
  VariableTransformTransform,
  assetsId,
  convertRecord,
  findComponent,
  generateFirestoreId,
  getCollectionProperties,
  getInputParameters,
  getNumberValue,
  getTextValue,
  getValueType,
  needOrderByOperators,
  operators,
  profilesId,
  queryOperators,
  readableError,
  reconvertRecord,
} from "../utils";

export type Refresh = { [key: string]: () => void };

export type GetVariable = (
  screenConfig: Screen,
  listId?: string,
  indexInList?: number
) => GetVariableValue;

export type GetVariableValue = (
  variable?: AnyVariable,
  refresh?: Refresh,
  toSet?: { value: any },
  initialSet?: boolean,
  listParams?: { listId: string; listItemContextKey: string; limit?: number }
) => Promise<any>;

export type Update = (
  getVariableValue: GetVariableValue,
  action: Action
) => Promise<void>;

export interface CollectionWithRecords extends Collection {
  collectionId: string;
  records: CMSCollectionRecord[];
  queryString?: string;
  limit?: number;
}

let variables: {
  [key: string]: any;
} = {};
let firebaseDatasources: { [key: string]: CMSCollectionRecord } = {};
const snapshots: { [key: string]: () => void } = {};
const refreshes: { [key: string]: Refresh[] } = {};

export const generateRefreshKey = (
  id: string,
  name: string,
  indexInList?: number
) => id + name + (indexInList !== undefined ? indexInList : "");

const addRefresh = (name: string, refresh?: Refresh) =>
  refresh &&
  (refreshes[name] = [
    ...(refreshes[name] || []).filter(
      (el) => Object.keys(el)[0] !== Object.keys(refresh)[0]
    ),
    refresh,
  ]);

const runRefreshes = (name: string) => {
  if (refreshes[name]) {
    let length = refreshes[name].length;
    for (let i = 0; i < length; i++) {
      const refresh = refreshes[name][i];
      refreshes[name].splice(i, 1);
      i--;
      length--;
      refresh[Object.keys(refresh)[0]]();
    }
  }
};

export const useVariable = (
  f: F,
  inDevice: boolean,
  desktopMode: boolean,
  config: Config,
  language: string,
  theme: BrandingThemeType,
  screen?: Screen,
  inputParameterValue?: string
) => {
  const {
    data: { collections = [], globalVariables = [] },
    branding: {
      appName,
      icons: { iosIcon },
      colorStyles,
    },
    resources = [],
    tabBars = [],
  } = config;
  const applicationName = appName.locales[language];
  const applicationIcon = resources.find((el) => el.id === iosIcon);
  const { firestorePrefix, auth, firestore, getAssetRecord, search } = f;

  const getVariable: GetVariable = (screenConfig, listId, indexInList) => {
    const getVariableValue: GetVariableValue = async (
      variable,
      refresh,
      toSet,
      initialSet,
      listParams
    ) => {
      if (variable) {
        const getVariableValue = getVariable(screenConfig, listId, indexInList);
        const {
          source,
          textConstant,
          numberConstant,
          booleanConstant,
          calendarStyleConstant,
          colorConstant,
          currencyConstant,
          durationConstant,
          urlConstant,
          imageConstant,
          videoConstant,
          audioConstant,
          dateConstant,
          dateTimeConstant,
          accessLevelConstant,
          arrayConstant,
          stringConstant,
        } = variable;
        if (source !== undefined) {
          const {
            type,
            collection,
            fieldName,
            selector,
            query,
            transforms,
            variableName,
            componentName,
          } = source;
          const screenId = screenConfig.id;
          switch (type) {
            case VariableSourceType.globalVariable:
              if (variableName) {
                let value = null;
                switch (variableName) {
                  case "currentUserId":
                    value = auth.currentUser?.uid;
                    break;
                  case "applicationVersion":
                    value = "v.1.0.1 (1)";
                    break;
                  case "applicationName":
                    value = applicationName;
                    break;
                  case "applicationIcon":
                    value = applicationIcon;
                    break;
                  case "isSignedOut":
                    value = !auth.currentUser;
                    break;
                  case "isAnonymous":
                    value = !!auth.currentUser?.isAnonymous;
                    break;
                  case "hasUserAccount":
                    value = !!auth.currentUser && !auth.currentUser.isAnonymous;
                    break;
                  case "isEmailVerified":
                    value = !!auth.currentUser?.emailVerified;
                    break;
                  case "accessLevels":
                    const globalVariable = globalVariables.find(
                      (el) => el.variableName === variableName
                    );
                    if (globalVariable) {
                      value = await getVariableValue(
                        globalVariable.variable,
                        refresh
                      );
                    }
                    break;
                }
                return convertValue(
                  value,
                  getVariableValue,
                  refresh,
                  transforms
                );
              }
              return null;
            case VariableSourceType.collection:
              if (collection) {
                const collectionId = await getCurrentCollectionId(
                  collection,
                  getVariableValue,
                  refresh
                );
                const collectionData = collections.find(
                  (el) => el.name === collection.name
                );
                if (collectionData) {
                  const firstTransform = transforms?.find(
                    (el) => el.transform === VariableTransformTransform.first
                  );
                  const { properties = [] } = collectionData;
                  const property = properties.find(
                    (el) => el.name === fieldName
                  );
                  const recordId = await getVariableValue(
                    { ...selector, stringConstant: selector?.constant },
                    refresh
                  );
                  if (listParams) {
                    const { listId, listItemContextKey, limit } = listParams;
                    const name = screenId + listId + listItemContextKey;
                    addRefresh(name, refresh);
                    const queryString = await queryToString(
                      name,
                      getVariableValue,
                      query
                    );
                    const variable = variables[name];
                    if (
                      !variable ||
                      (typeof variable === "object" &&
                        (variable.collectionId !== collectionId ||
                          variable.queryString !== queryString ||
                          variable.limit !== limit))
                    ) {
                      if (recordId) {
                        const firebaseDatasourceName = `${collectionId}/${recordId}`;
                        const set = async (record: CMSCollectionRecord) => {
                          firebaseDatasources[firebaseDatasourceName] = record;
                          if (fieldName && record[fieldName] && property) {
                            const subCollection =
                              property.type === ValueType.array &&
                              property.accept === ValueType.record &&
                              collections.find(
                                (el) =>
                                  property.collection &&
                                  el.name === property.collection.name
                              );
                            if (subCollection) {
                              const recordsIds = record[fieldName].map(
                                (el: string) => extractRecordRef(el).recordId
                              );
                              if (recordsIds.length) {
                                const set = (
                                  records: CMSCollectionRecord[]
                                ) => {
                                  variables[name] = {
                                    ...subCollection,
                                    collectionId,
                                    records,
                                    queryString,
                                    limit,
                                  };
                                };
                                await getRecords(
                                  name,
                                  set,
                                  subCollection.name,
                                  queryString,
                                  limit,
                                  recordsIds
                                );
                              }
                            }
                          }
                        };
                        if (!firebaseDatasources[firebaseDatasourceName]) {
                          await getRecord(set, collectionId, recordId);
                        } else {
                          await set(
                            firebaseDatasources[firebaseDatasourceName]
                          );
                        }
                      } else {
                        const set = (records: CMSCollectionRecord[]) => {
                          variables[name] = {
                            ...collectionData,
                            collectionId,
                            records,
                            queryString,
                            limit,
                          };
                        };
                        await getRecords(
                          name,
                          set,
                          collectionId,
                          queryString,
                          limit
                        );
                      }
                    }
                    return variables[name];
                  } else if (recordId && fieldName && property) {
                    const record = await getFirebaseDatasourceRecord(
                      collectionId,
                      recordId,
                      refresh
                    );
                    return convertValue(
                      record[fieldName],
                      getVariableValue,
                      refresh,
                      transforms,
                      property
                    );
                  } else if (recordId && transforms) {
                    const record = await getFirebaseDatasourceRecord(
                      collectionId,
                      recordId,
                      refresh
                    );
                    return convertValue(
                      record,
                      getVariableValue,
                      refresh,
                      transforms,
                      undefined,
                      true
                    );
                  } else if (recordId && initialSet) {
                    const record = await getFirebaseDatasourceRecord(
                      collectionId,
                      recordId,
                      refresh
                    );
                    return {
                      ...collectionData,
                      collectionId,
                      records: [record],
                    };
                  } else if (recordId) {
                    if (collectionId === assetsId) {
                      const record = await getFirebaseDatasourceRecord(
                        collectionId,
                        recordId,
                        refresh
                      );
                      return record;
                    } else {
                      return `${collectionPrefix}${collectionId}/${recordId}`;
                    }
                  } else if (firstTransform) {
                    const name = screenId + collectionId + "first";
                    addRefresh(name, refresh);
                    const queryString = await queryToString(
                      name,
                      getVariableValue,
                      query
                    );
                    let variable: CollectionWithRecords | undefined;
                    const set = (records: CMSCollectionRecord[]) => {
                      variable = {
                        ...collectionData,
                        collectionId,
                        records,
                      };
                    };
                    await getRecords(name, set, collectionId, queryString, 1);
                    if (initialSet) {
                      return variable;
                    } else {
                      const recordId = variable?.records[0]?.id;
                      return `${collectionPrefix}${collectionId}/${recordId}`;
                    }
                  } else if (transforms) {
                    return convertValue(
                      {},
                      getVariableValue,
                      refresh,
                      transforms,
                      undefined,
                      true
                    );
                  } else if (collectionId === assetsId && initialSet) {
                    return {
                      ...collectionData,
                      collectionId,
                      records: [{}],
                    };
                  } else if (fieldName === "count") {
                    const name = screenId + collectionId + "count";
                    addRefresh(name, refresh);
                    const queryString = await queryToString(
                      name,
                      getVariableValue,
                      query
                    );
                    const variable = variables[name];
                    if (variable === undefined) {
                      const set = (records: CMSCollectionRecord[]) => {
                        variables[name] = records.length;
                      };
                      await getRecords(name, set, collectionId, queryString);
                    }
                    return variables[name];
                  }
                }
              }
              return null;
            case VariableSourceType.localVariable:
              if (variableName) {
                const innerListItemValue =
                  variables[screenId + listId + variableName + indexInList];
                const listItemsValue =
                  variables[screenId + listId + variableName];
                if (toSet !== undefined) {
                  let name;
                  if (initialSet) {
                    name =
                      listId && indexInList !== undefined
                        ? screenId + listId + variableName + indexInList
                        : listId
                        ? screenId + listId + variableName
                        : screenId + variableName;
                  } else {
                    name =
                      innerListItemValue !== undefined
                        ? screenId + listId + variableName + indexInList
                        : listItemsValue !== undefined
                        ? screenId + listId + variableName
                        : screenId + variableName;
                  }
                  if (
                    JSON.stringify(variables[name]) !==
                    JSON.stringify(toSet.value)
                  ) {
                    variables[name] = toSet.value;
                    runRefreshes(name);
                  }
                  return variables[name];
                }
                const name =
                  innerListItemValue !== undefined
                    ? screenId + listId + variableName + indexInList
                    : listItemsValue !== undefined
                    ? screenId + listId + variableName
                    : screenId + variableName;
                const localRefresh = innerListItemValue || !listItemsValue;
                if (localRefresh) {
                  addRefresh(name, refresh);
                }
                const variable = variables[name];
                if (
                  variable &&
                  typeof variable === "object" &&
                  variable.name &&
                  variable.collectionId &&
                  variable.records
                ) {
                  const { name, collectionId: cId, records } = variable;
                  const collectionId = cId || name;
                  const record =
                    innerListItemValue !== undefined
                      ? records[0] || {}
                      : listItemsValue !== undefined
                      ? records[indexInList || 0] || {}
                      : records[0] || {};
                  const recordId = record.id as string | undefined;
                  if (recordId) {
                    const firebaseDatasourceName = `${collectionId}/${recordId}`;
                    firebaseDatasources[firebaseDatasourceName] = record;
                  }
                  return getVariableValue(
                    {
                      source: {
                        type: VariableSourceType.collection,
                        collection: { name, collectionId },
                        selector: { constant: recordId },
                        fieldName,
                        query,
                        transforms,
                      },
                    },
                    localRefresh ? undefined : refresh,
                    toSet,
                    initialSet,
                    listParams
                  );
                } else {
                  return convertValue(
                    variable,
                    getVariableValue,
                    refresh,
                    transforms
                  );
                }
              }
              return null;
            case VariableSourceType.component:
              if (componentName && fieldName) {
                const name = screenId + componentName + fieldName;
                if (toSet !== undefined) {
                  if (
                    JSON.stringify(variables[name]) !==
                    JSON.stringify(toSet.value)
                  ) {
                    variables[name] = toSet.value;
                    runRefreshes(name);
                  }
                  return variables[name];
                }
                addRefresh(name, refresh);
                let variable = variables[name];
                if (variable === undefined) {
                  const component = findComponent(
                    screenConfig,
                    "name",
                    componentName
                  );
                  if (component) {
                    const fieldValue =
                      component[fieldName as keyof ScreenComponent];
                    if (fieldName === "text") {
                      const text = fieldValue as LocalizedContent | undefined;
                      variable = await getVariableValue(
                        { textConstant: text },
                        refresh
                      );
                    } else if (
                      fieldName === "date" ||
                      fieldName === "displayDate"
                    ) {
                      const date = fieldValue as DateVariable | undefined;
                      variable = await getVariableValue(
                        { ...date, dateConstant: date?.constant },
                        refresh
                      );
                    } else if (fieldName === "value") {
                      const value = fieldValue as NumberVariable | undefined;
                      variable = await getVariableValue(
                        { ...value, numberConstant: value?.constant },
                        refresh
                      );
                    } else if (fieldName === "offer") {
                      const offer = fieldValue as VariableSource | undefined;
                      variable = await getVariableValue(
                        { source: offer },
                        refresh
                      );
                    } else if (fieldName === "visibleItem") {
                      const { id, listItems, listItemContextKey } = component;
                      const listId = id;
                      if (listItemContextKey) {
                        const listItemsValue = (await getVariableValue(
                          listItems,
                          refresh,
                          undefined,
                          undefined,
                          { listId, listItemContextKey }
                        )) as CollectionWithRecords | undefined;
                        const valueValue = (await getVariableValue(
                          {
                            source: {
                              type: VariableSourceType.component,
                              componentName,
                              fieldName: "value",
                            },
                          },
                          refresh
                        )) as number | undefined;
                        if (listItemsValue && typeof valueValue === "number") {
                          const { collectionId, records } = listItemsValue;
                          if (records[valueValue]) {
                            const recordId = records[valueValue].id;
                            variable = `${collectionPrefix}${collectionId}/${recordId}`;
                          }
                        }
                      }
                    }
                  }
                }
                return convertValue(
                  variable,
                  getVariableValue,
                  refresh,
                  transforms
                );
              }
              return null;
            default:
              return null;
          }
        } else if (textConstant !== undefined) {
          return getTextValue(
            language,
            getVariableValue,
            refresh,
            textConstant
          );
        } else if (numberConstant !== undefined) {
          const { screenName, showTopBar } = screenConfig;
          const tabBar = tabBars.find((el) =>
            el.tabs?.find((el) => el.screen === screenName)
          );
          const showTopBarValue = await getVariableValue({
            ...showTopBar,
            booleanConstant: showTopBar?.constant,
          });
          return getNumberValue(
            numberConstant,
            showTopBarValue,
            !!tabBar,
            desktopMode,
            inDevice
          );
        } else if (booleanConstant !== undefined) {
          return booleanConstant;
        } else if (calendarStyleConstant !== undefined) {
          return calendarStyleConstant;
        } else if (colorConstant !== undefined) {
          const color =
            colorStyles[colorConstant?.slice(1) as BrandingColorType];
          return color ? color[theme] : colorConstant;
        } else if (currencyConstant !== undefined) {
          return currencyConstant;
        } else if (durationConstant !== undefined) {
          return durationConstant;
        } else if (urlConstant !== undefined) {
          return urlConstant;
        } else if (imageConstant !== undefined) {
          return resources.find((el) => el.id === imageConstant.resourceId);
        } else if (videoConstant !== undefined) {
          return resources.find((el) => el.id === videoConstant.resourceId);
        } else if (audioConstant !== undefined) {
          return resources.find((el) => el.id === audioConstant.resourceId);
        } else if (dateConstant !== undefined) {
          return dateConstant === "@today"
            ? new Date().toISOString()
            : dateConstant;
        } else if (dateTimeConstant !== undefined) {
          return dateTimeConstant === "@now"
            ? new Date().toISOString()
            : dateTimeConstant;
        } else if (accessLevelConstant !== undefined) {
          return accessLevelConstant;
        } else if (arrayConstant !== undefined) {
          const value = [];
          for (const el of arrayConstant) {
            value.push(await getVariableValue(el, refresh));
          }
          return value;
        } else if (stringConstant !== undefined) {
          return stringConstant;
        }
        return null;
      }
      return null;
    };
    return getVariableValue;
  };

  const convertValue = async (
    value: any,
    getVariableValue: GetVariableValue,
    refresh?: Refresh,
    transforms?: VariableTransform[],
    property?: CollectionProperty,
    record?: boolean
  ) => {
    const type = property?.type;
    const collection = property?.collection;

    if (type === ValueType.color) {
      value = await getVariableValue({ colorConstant: value }, refresh);
    }

    if (type === ValueType.richText && typeof value === "string") {
      value = marked(value, { async: false }) as string;
    }

    if (transforms) {
      for (const el of transforms) {
        const {
          transform,
          fieldName,
          participantsKey,
          participantNameKeys,
          participantImageKey,
          value: transformValue1,
          value2: transformValue2,
          type: transformType,
        } = el;
        const value1 = await getVariableValue(transformValue1, refresh);
        const value2 = await getVariableValue(transformValue2, refresh);
        switch (transform) {
          case VariableTransformTransform.matchesRegexp:
            if (typeof value === "string" && typeof value1 === "string") {
              value = new RegExp(value1).test(value);
            }
            break;
          case VariableTransformTransform.formatAsCurrency:
            if (typeof value === "number" && typeof value1 === "string") {
              value = new Intl.NumberFormat(language, {
                style: "currency",
                currency: value1,
              }).format(value);
            }
            break;
          case VariableTransformTransform.contains:
            if (typeof value === "string" || Array.isArray(value)) {
              value = value.includes(value1);
            }
            break;
          case VariableTransformTransform.throttle:
            if (typeof value1 === "number") {
              await new Promise((res) => setTimeout(res, value1));
            }
            break;
          case VariableTransformTransform.trimWhitespacesAndNewlines:
            if (typeof value === "string") {
              value = value.trim();
            }
            break;
          case VariableTransformTransform.capitalizeFirstLetter:
            if (typeof value === "string" && value[0]) {
              value = value[0].toUpperCase() + value.slice(1);
            }
            break;
          case VariableTransformTransform.identifier:
            value = record ? value.id : null;
            break;
          case VariableTransformTransform.exists:
          case VariableTransformTransform.isNotEmpty:
            value = record
              ? !!value.id
              : !!value || value === 0 || value === false;
            break;
          case VariableTransformTransform.isEmpty:
            value = record
              ? !value.id
              : !value && value !== 0 && value !== false;
            break;
          case VariableTransformTransform.boolNot:
            value = !value;
            break;
          case VariableTransformTransform.anyTrue:
            if (Array.isArray(value)) {
              value = value.some((el: any) => !!el);
            }
            break;
          case VariableTransformTransform.allTrue:
            if (Array.isArray(value)) {
              value = value.every((el: any) => !!el);
            }
            break;
          case VariableTransformTransform.conditionalValue:
            value = value ? value1 : value2;
            break;
          case VariableTransformTransform.formatTimeAgo:
            if (typeof value === "string") {
              value = format(value);
            }
            break;
          case VariableTransformTransform.formatTimeInterval:
            if (typeof value === "number") {
              const fullTime = new Date(value * 1000)
                .toISOString()
                .slice(11, 19);
              value = fullTime.startsWith("00:")
                ? fullTime.slice(3, 8).startsWith("0")
                  ? fullTime.slice(4, 8)
                  : fullTime.slice(3, 8)
                : fullTime.startsWith("0")
                ? fullTime.slice(1)
                : fullTime;
            }
            break;
          case VariableTransformTransform.compareEqual:
          case VariableTransformTransform.compareNotEqual:
          case VariableTransformTransform.compareGreater:
          case VariableTransformTransform.compareLess:
          case VariableTransformTransform.compareGreaterOrEqual:
          case VariableTransformTransform.compareLessOrEqual:
            value =
              transform === VariableTransformTransform.compareEqual
                ? Array.isArray(value)
                  ? JSON.stringify(value) === JSON.stringify(value1)
                  : value === value1
                : transform === VariableTransformTransform.compareNotEqual
                ? value !== value1
                : transform === VariableTransformTransform.compareGreater
                ? value > value1
                : transform === VariableTransformTransform.compareLess
                ? value < value1
                : transform === VariableTransformTransform.compareGreaterOrEqual
                ? value >= value1
                : value <= value1;
            break;
          case VariableTransformTransform.add:
          case VariableTransformTransform.sub:
          case VariableTransformTransform.mult:
          case VariableTransformTransform.div:
            if (typeof value === "number" && typeof value1 === "number") {
              value =
                transform === VariableTransformTransform.add
                  ? value + value1
                  : transform === VariableTransformTransform.sub
                  ? value - value1
                  : transform === VariableTransformTransform.mult
                  ? value * value1
                  : value / value1;
            }
            break;
          case VariableTransformTransform.cast:
            if (transformType === ValueType.string) {
              value = String(value);
            } else if (transformType === ValueType.number) {
              value = Number(value);
            } else if (transformType === ValueType.boolean) {
              value = Boolean(value);
            }
            break;
          case VariableTransformTransform.field:
            if (fieldName) {
              if (type === ValueType.record && collection) {
                const { collectionId, recordId } = extractRecordRef(value);
                value = await getVariableValue(
                  {
                    source: {
                      type: VariableSourceType.collection,
                      collection: { ...collection, collectionId },
                      selector: { constant: recordId },
                      fieldName,
                    },
                  },
                  refresh
                );
              } else {
                value = value?.[fieldName];
              }
            }
            break;
          case VariableTransformTransform.conversationTitle:
          case VariableTransformTransform.conversationImage:
            if (fieldName && value[fieldName]) {
              value = value[fieldName];
            } else if (
              value &&
              participantsKey &&
              value[participantsKey] &&
              value[participantsKey].length === 2 &&
              participantNameKeys
            ) {
              const currentUserId = await getVariableValue(
                {
                  source: {
                    type: VariableSourceType.globalVariable,
                    variableName: "currentUserId",
                  },
                },
                refresh
              );
              const participantId = value[participantsKey].find(
                (el: string) => el !== currentUserId
              );
              if (transform === VariableTransformTransform.conversationTitle) {
                value = await getParticipantFullName(
                  participantId,
                  participantNameKeys,
                  getVariableValue,
                  refresh
                );
              } else if (participantImageKey) {
                value = await getVariableValue(
                  {
                    source: {
                      type: VariableSourceType.collection,
                      collection: { name: profilesId },
                      selector: { constant: participantId },
                      fieldName: participantImageKey,
                    },
                  },
                  refresh
                );
                if (!value) {
                  value = await createAvatar(
                    participantId,
                    participantNameKeys,
                    getVariableValue,
                    refresh
                  );
                }
              }
            } else {
              value = "";
            }
            break;
        }
      }
    }

    return value;
  };

  const getFirebaseDatasourceRecord = async (
    collectionId: string,
    recordId: string,
    refresh?: Refresh
  ) => {
    const firebaseDatasourceName = `${collectionId}/${recordId}`;
    addRefresh(firebaseDatasourceName, refresh);
    if (!firebaseDatasources[firebaseDatasourceName]) {
      const set = (record: CMSCollectionRecord) => {
        firebaseDatasources[firebaseDatasourceName] = record;
      };
      await getRecord(set, collectionId, recordId);
    }
    return firebaseDatasources[firebaseDatasourceName];
  };

  const getRecord = (
    set: (record: CMSCollectionRecord) => void,
    collectionId: string,
    recordId: string
  ) =>
    new Promise((resolve) => {
      const properties = getCollectionProperties(collections, collectionId);
      const assetsProperties = getCollectionProperties(collections, assetsId);
      const firebaseDatasourceName = `${collectionId}/${recordId}`;
      let loaded = false;
      const setRecord = async (record: CMSCollectionRecord) => {
        await set(record);
        setTimeout(() => {
          if (loaded) {
            runRefreshes(firebaseDatasourceName);
          }
          loaded = true;
          resolve(true);
        }, 0);
      };
      snapshots[firebaseDatasourceName]?.();
      snapshots[firebaseDatasourceName] = firestore
        .collection(firestorePrefix + collectionId)
        .doc(recordId)
        .onSnapshot({
          next: (res) =>
            setRecord(convertRecord(res, properties, assetsProperties)),
          error: (err) => {
            alert(readableError(err.message));
            setRecord({});
          },
        });
    });

  const getRecords = (
    name: string,
    set: (records: CMSCollectionRecord[]) => void,
    collectionId: string,
    queryString: string,
    limit?: number,
    ids?: string[]
  ) =>
    new Promise(async (resolve) => {
      const properties = getCollectionProperties(collections, collectionId);
      const assetsProperties = getCollectionProperties(collections, assetsId);
      let loaded = false;
      const setRecords = async (records: CMSCollectionRecord[]) => {
        await set(records);
        setTimeout(() => {
          if (loaded) {
            runRefreshes(name);
          }
          loaded = true;
          resolve(true);
        }, 0);
      };
      const { filters, ordered } = (
        queryString ? JSON.parse(queryString) : { filters: [], ordered: [] }
      ) as {
        filters: {
          fieldPath: string;
          opStr: firebase.firestore.WhereFilterOp;
          value: any;
        }[];
        ordered: {
          fieldPath: string;
          direction: firebase.firestore.OrderByDirection;
        }[];
      };
      if (ids) {
        filters.push({
          fieldPath: "id",
          opStr: TableQueryFilterOperator.in,
          value: ids,
        });
      }
      const apiCall = !!filters.find(({ fieldPath }) =>
        properties.find(
          ({ name, type }) => name === fieldPath && type === ValueType.vector
        )
      );
      if (apiCall) {
        snapshots[name]?.();
        search(
          `collections/${
            firestorePrefix + collectionId
          }?query=${encodeURIComponent(
            JSON.stringify({
              where: {
                compositeFilter: {
                  op: "AND",
                  filters: filters.map(({ fieldPath, opStr, value }) => ({
                    fieldFilter: {
                      field: { fieldPath },
                      op: queryOperators[opStr],
                      value: { [getValueType(properties, fieldPath)]: value },
                    },
                  })),
                },
              },
              orderBy: ordered.map(({ fieldPath, direction }) => ({
                field: { fieldPath },
                direction: direction === "asc" ? "ASCENDING" : "DESCENDING",
              })),
              limit,
            })
          )}`
        )
          .then((res) => setRecords(res.data))
          .catch((err) => {
            alert(err);
            setRecords([]);
          });
      } else {
        let collectionQuery: firebase.firestore.Query;
        collectionQuery = firestore.collection(firestorePrefix + collectionId);
        filters.forEach(
          ({ fieldPath, opStr, value }) =>
            (collectionQuery = collectionQuery.where(
              fieldPath === "id"
                ? firebase.firestore.FieldPath.documentId()
                : fieldPath,
              opStr,
              value
            ))
        );
        ordered.forEach(
          ({ fieldPath, direction }) =>
            (collectionQuery = collectionQuery.orderBy(
              fieldPath === "id"
                ? firebase.firestore.FieldPath.documentId()
                : fieldPath,
              direction
            ))
        );
        if (limit) {
          collectionQuery = collectionQuery.limit(limit);
        }
        snapshots[name]?.();
        snapshots[name] = collectionQuery.onSnapshot(
          { includeMetadataChanges: true },
          {
            next: (res) =>
              !res.metadata.fromCache &&
              !res.metadata.hasPendingWrites &&
              setRecords(
                res.docs.map((el) =>
                  convertRecord(el, properties, assetsProperties)
                )
              ),
            error: (err) => {
              if (err.code === "failed-precondition") {
                const filterFields = [
                  ...filters.map(({ fieldPath, opStr }) =>
                    opStr === operators.contains
                      ? { fieldPath, arrayConfig: "CONTAINS" }
                      : { fieldPath, order: "DESCENDING" }
                  ),
                  ...ordered.map(({ fieldPath, direction }) => ({
                    fieldPath,
                    order: direction === "asc" ? "ASCENDING" : "DESCENDING",
                  })),
                ];
                window.parent.postMessage(
                  `NEED_INDEX ${JSON.stringify({
                    collectionId,
                    filterFields,
                  })}`,
                  "*"
                );
              }
              alert(readableError(err.message));
              setRecords([]);
            },
          }
        );
      }
    });

  const update: Update = async (getVariableValue, action) => {
    const updateRecord = async (
      newRecord: CMSCollectionRecord,
      source: VariableSource
    ) => {
      const record = await getVariableValue({
        source:
          source.type === VariableSourceType.component
            ? source
            : { ...source, fieldName: undefined },
      });
      const { collectionId, recordId } = extractRecordRef(record);
      const properties = getCollectionProperties(collections, collectionId);
      const assetsProperties = getCollectionProperties(collections, assetsId);
      reconvertRecord(newRecord, properties, assetsProperties);
      await firestore
        .collection(firestorePrefix + collectionId)
        .doc(recordId)
        .update({
          ...newRecord,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    };
    const createRecord = async (
      newRecord: CMSCollectionRecord,
      collection: CollectionVariable,
      valueTarget?: VariableSource
    ) => {
      const collectionId = await getCurrentCollectionId(
        collection,
        getVariableValue
      );
      const collectionData = collections.find(
        (el) => el.name === collection.name
      );
      if (collectionData) {
        const { properties = [] } = collectionData;
        const assetsProperties = getCollectionProperties(collections, assetsId);
        const currentUserId = await getVariableValue({
          source: {
            type: VariableSourceType.globalVariable,
            variableName: "currentUserId",
          },
        });
        const recordId = newRecord.id || generateFirestoreId();
        reconvertRecord(newRecord, properties, assetsProperties);
        const recordReference = firestore
          .collection(firestorePrefix + collectionId)
          .doc(recordId);
        await recordReference.set({
          ...newRecord,
          ownerId: currentUserId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        if (valueTarget) {
          const set = async (record: CMSCollectionRecord) => {
            const firebaseDatasourceName = `${collectionId}/${recordId}`;
            firebaseDatasources[firebaseDatasourceName] = record;
            const { fieldName, variableName } = valueTarget;
            if (fieldName) {
              await updateRecord({ [fieldName]: record }, valueTarget);
            } else if (variableName) {
              const value = {
                ...collectionData,
                collectionId,
                records: [record],
              };
              await getVariableValue(
                {
                  source: {
                    type: VariableSourceType.localVariable,
                    variableName,
                  },
                },
                undefined,
                { value },
                true
              );
            }
          };
          await getRecord(set, collectionId, recordId);
        }
      }
    };
    const deleteRecord = async (source: VariableSource) => {
      const record = await getVariableValue({ source });
      const { collectionId, recordId } = extractRecordRef(record);
      const recordReference = firestore
        .collection(firestorePrefix + collectionId)
        .doc(recordId);
      await recordReference.delete();
    };
    const getNewRecord = async (recordModifications: RecordModification[]) => {
      const newRecord: CMSCollectionRecord = {};
      for (const el of recordModifications) {
        const { fieldName, value } = el;
        if (fieldName) {
          newRecord[fieldName] = await getVariableValue(value);
        }
      }
      return newRecord;
    };
    const {
      valueTarget,
      collection,
      recordModifications,
      record,
      actionType,
      variable,
    } = action;
    if (
      actionType === ActionType.getImage ||
      actionType === ActionType.getVideo ||
      actionType === ActionType.getAudio ||
      actionType === ActionType.getFile
    ) {
      return new Promise((resolve, reject) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept =
          actionType === ActionType.getImage
            ? "image/*"
            : actionType === ActionType.getVideo
            ? "video/*"
            : actionType === ActionType.getAudio
            ? "audio/*"
            : "*";
        let cancelled = true;
        input.onchange = async (e: any) => {
          cancelled = false;
          const file = e.target.files[0];
          if (file) {
            const newRecord = await getAssetRecord(file);
            if (newRecord) {
              await createRecord(newRecord, { name: assetsId }, valueTarget);
              resolve();
            }
          }
          reject(new Error("Something went wrong"));
        };
        window.addEventListener(
          "focus",
          () => setTimeout(() => cancelled && reject(), 1000),
          { once: true }
        );
        input.click();
      });
    } else if (
      actionType === ActionType.createRecord &&
      recordModifications &&
      collection
    ) {
      const newRecord = await getNewRecord(recordModifications);
      await createRecord(newRecord, collection, valueTarget);
    } else if (
      actionType === ActionType.updateRecord &&
      recordModifications &&
      record
    ) {
      const newRecord = await getNewRecord(recordModifications);
      await updateRecord(newRecord, record);
    } else if (actionType === ActionType.deleteRecord && record) {
      await deleteRecord(record);
    } else if (actionType === ActionType.setValue && valueTarget && variable) {
      const value = await getVariableValue(variable);
      await getVariableValue({ source: valueTarget }, undefined, { value });
    } else if (actionType === ActionType.clearValue && valueTarget) {
      const value = null;
      await getVariableValue({ source: valueTarget }, undefined, { value });
    }
  };

  useEffect(() => {
    variables = {};
    firebaseDatasources = {};
    Object.keys(snapshots).forEach((el) => {
      snapshots[el]();
      delete snapshots[el];
    });
  }, [auth.currentUser]);

  useEffect(() => {
    return () => {
      Object.keys(refreshes).forEach((el) => delete refreshes[el]);
    };
  }, [inputParameterValue, screen]);

  return { getVariable, update };
};

const collectionPrefix = "collection://";

const extractRecordRef = (recordRef: string) => {
  const segments = recordRef.replace(collectionPrefix, "").split("/");
  const collectionId = segments.slice(0, -1).join("/");
  const recordId = segments.slice(-1)[0];
  return { collectionId, recordId };
};

const getCurrentCollectionId = async (
  collection: CollectionVariable,
  getVariableValue: GetVariableValue,
  refresh?: Refresh
) => {
  const { collectionId, name, params } = collection;
  if (collectionId) {
    return collectionId;
  } else {
    let collectionId = name;
    if (params) {
      for (const el of Object.keys(params)) {
        const param = params[el];
        const paramValue = await getVariableValue(
          { ...param, stringConstant: param.constant },
          refresh
        );
        if (paramValue) {
          collectionId = collectionId.replace(`{${el}}`, paramValue);
        }
      }
    }
    return collectionId;
  }
};

export const queryToString = async (
  name: string,
  getVariableValue: GetVariableValue,
  query?: TableQuery
) => {
  const filters: {
    fieldPath: string;
    opStr: firebase.firestore.WhereFilterOp;
    value: any;
  }[] = [];
  const ordered: {
    fieldPath: string;
    direction: firebase.firestore.OrderByDirection;
  }[] = [];
  if (query?.filters) {
    for (const el of query.filters) {
      const value = await getVariableValue(el.value, {
        [name + el.field + "variable"]: () => runRefreshes(name),
      });
      if (value !== "" && value !== null && value !== undefined) {
        const opStr = operators[el.operator];
        filters.push({ fieldPath: el.field, opStr, value });
        if (needOrderByOperators.includes(opStr)) {
          ordered.push({ fieldPath: el.field, direction: "desc" });
        }
      }
    }
  }
  if (query?.ordered) {
    for (const el of query.ordered) {
      ordered.push({
        fieldPath: el.field,
        direction: el.order === TableQueryOrder.ascending ? "asc" : "desc",
      });
    }
  } else {
    ordered.push({ fieldPath: "createdAt", direction: "desc" });
  }
  return JSON.stringify({ filters, ordered });
};

const getParticipantFullName = async (
  participantId: string,
  participantNameKeys: string[],
  getVariableValue: GetVariableValue,
  refresh?: Refresh
) => {
  const fields: string[] = [];
  for (const fieldName of participantNameKeys) {
    fields.push(
      await getVariableValue(
        {
          source: {
            type: VariableSourceType.collection,
            collection: { name: profilesId },
            selector: { constant: participantId },
            fieldName,
          },
        },
        refresh
      )
    );
  }
  return fields.join(" ");
};

const createAvatar = async (
  participantId: string,
  participantNameKeys: string[],
  getVariableValue: GetVariableValue,
  refresh?: Refresh
) => {
  const randomColor = () =>
    "#" + (0x1000000 | (Math.random() * 0xffffff)).toString(16).substr(1, 6);
  const canvas = document.createElement("canvas");
  canvas.width = 100;
  canvas.height = 100;
  const context = canvas.getContext("2d");
  if (context) {
    context.fillStyle = randomColor();
    context.beginPath();
    context.ellipse(
      canvas.width / 2,
      canvas.height / 2,
      canvas.width / 2,
      canvas.height / 2,
      0,
      0,
      Math.PI * 2
    );
    context.fill();
    context.font = "60px serif";
    context.fillStyle = randomColor();
    context.textAlign = "center";
    context.textBaseline = "middle";
    const initials = (
      await getParticipantFullName(
        participantId,
        participantNameKeys,
        getVariableValue,
        refresh
      )
    )
      .split(" ")
      .map((el) => el[0] || "")
      .join("");
    context.fillText(initials, canvas.width / 2, canvas.height / 2);
  }
  return canvas.toDataURL();
};

export const setInputParameters = async (
  id: string,
  inputParameters: ScreenParameter[],
  getVariableValue: GetVariableValue,
  inputParameterValue?: string
) => {
  const { inputParameter } = getInputParameters(inputParameters);
  if (inputParameter) {
    const { parameter, type, collection } = inputParameter;
    if (inputParameterValue) {
      if (type === ValueType.record) {
        const { recordId } = extractRecordRef(inputParameterValue);
        const refresh = async () => {
          const value = await getVariableValue(
            {
              source: {
                type: VariableSourceType.collection,
                collection,
                selector: { constant: recordId },
              },
            },
            { [generateRefreshKey(id, parameter + "parameter")]: refresh },
            undefined,
            true
          );
          await getVariableValue(
            {
              source: {
                type: VariableSourceType.localVariable,
                variableName: parameter,
              },
            },
            undefined,
            { value },
            true
          );
        };
        await refresh();
      } else {
        await getVariableValue(
          {
            source: {
              type: VariableSourceType.localVariable,
              variableName: parameter,
            },
          },
          undefined,
          { value: inputParameterValue },
          true
        );
      }
    }
  }
};

export const setLocalVariables = async (
  id: string,
  getVariableValue: GetVariableValue,
  localVariables: LocalVariable[],
  indexInList?: number
) => {
  for (const el of localVariables) {
    const { name, variable, type, collection } = el;
    if (variable) {
      const refresh = async () => {
        const value = await getVariableValue(
          variable,
          { [generateRefreshKey(id, name + "variable", indexInList)]: refresh },
          undefined,
          true
        );
        await getVariableValue(
          {
            source: {
              type: VariableSourceType.localVariable,
              variableName: name,
            },
          },
          undefined,
          { value },
          true
        );
      };
      await refresh();
    } else if (type === ValueType.record) {
      const refresh = async () => {
        const value = await getVariableValue(
          { source: { type: VariableSourceType.collection, collection } },
          { [generateRefreshKey(id, name + "variable", indexInList)]: refresh },
          undefined,
          true
        );
        await getVariableValue(
          {
            source: {
              type: VariableSourceType.localVariable,
              variableName: name,
            },
          },
          undefined,
          { value },
          true
        );
      };
      await refresh();
    } else if (
      type === ValueType.image ||
      type === ValueType.video ||
      type === ValueType.audio ||
      type === ValueType.file
    ) {
      const refresh = async () => {
        const value = await getVariableValue(
          {
            source: {
              type: VariableSourceType.collection,
              collection: { name: assetsId },
            },
          },
          { [generateRefreshKey(id, name + "variable", indexInList)]: refresh },
          undefined,
          true
        );
        await getVariableValue(
          {
            source: {
              type: VariableSourceType.localVariable,
              variableName: name,
            },
          },
          undefined,
          { value },
          true
        );
      };
      await refresh();
    } else {
      const value = null;
      await getVariableValue(
        {
          source: {
            type: VariableSourceType.localVariable,
            variableName: name,
          },
        },
        undefined,
        { value },
        true
      );
    }
  }
};

export const getParams = async (
  parameters: InputParameter[],
  getVariableValue: GetVariableValue
) => {
  const params: { [key: string]: any } = {};
  for (const el of parameters) {
    const { name, value } = el;
    if (name) {
      params[name] = await getVariableValue(value);
    }
  }
  return params;
};
