import firebase from "firebase/compat/app";

import { GetVariableValue } from "../hooks";
import {
  AnyVariable,
  VariableSourceType,
  addCloseStripeButton,
  profilesId,
  showStripe,
} from "../utils";

export const checkoutSelected = async (
  auth: firebase.auth.Auth,
  variable: AnyVariable,
  getVariableValue: GetVariableValue
) =>
  new Promise(async (resolve, reject) => {
    try {
      const { id, accessLevels = [] } = {} as any;
      const token = await auth.currentUser?.getIdToken();
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/checkout/sessions`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ productId: id }),
        }
      );
      // @ts-ignore
      const stripe = Stripe(
        process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY
      );
      const { clientSecret } = await res.json();
      const checkout = await stripe.initEmbeddedCheckout({ clientSecret });
      showStripe();
      checkout.mount("#stripe");
      const timer = setInterval(async () => {
        const profileAccessLevels = await getVariableValue({
          source: {
            selector: {
              source: {
                type: VariableSourceType.globalVariable,
                variableName: "currentUserId",
              },
            },
            collection: { name: profilesId },
            type: VariableSourceType.collection,
            fieldName: "accessLevels",
          },
        });
        if (profileAccessLevels) {
          for (const accessLevel of accessLevels) {
            if (!profileAccessLevels.includes(accessLevel)) {
              return;
            }
          }
        }
        close();
      }, 1000);
      const close = () => {
        clearInterval(timer);
        checkout.destroy();
        resolve(true);
      };
      addCloseStripeButton(close);
    } catch (err) {
      reject(err);
    }
  });
