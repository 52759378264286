import { GetVariableValue, Refresh } from "../hooks";
import {
  ColorVariable,
  ComponentType,
  CoordinateConstant,
  ImageVariable,
  LocalizedContent,
  Screen,
  ScreenComponent,
  ScreenParameter,
  TextAlignment,
} from "./index";

export enum BarComponentType {
  tabBar = "tabBar",
  tab = "tab",
  topBar = "topBar",
}

export const getComponentTypeById = (id: string) =>
  id.split(".")[0] as ComponentType | BarComponentType;

export const isParentComponent = (
  componentType: ComponentType | BarComponentType
) =>
  componentType === ComponentType.overlay ||
  componentType === ComponentType.scroll ||
  componentType === ComponentType.stack ||
  componentType === ComponentType.carousel ||
  componentType === ComponentType.list;

export enum ISize {
  buttonHeight = "@buttonHeight",
  fieldHeight = "@fieldHeight",
  cornersRadius = "@cornersRadius",
  max = "@max",
  topScreenMargins = "@topScreenMargins",
  trailingScreenMargins = "@trailingScreenMargins",
  bottomScreenMargins = "@bottomScreenMargins",
  leadingScreenMargins = "@leadingScreenMargins",
  thinSeparator = "@thinSeparator",
  horizontalMargin = "@horizontalMargin",
  horizontalSpacing = "@horizontalSpacing",
  verticalSpacing = "@verticalSpacing",
}

export enum ILocalSize {
  topBarHeight = "@topBarHeight",
  tabBarHeight = "@tabBarHeight",
}

export const getNumberValue = (
  value: string,
  hasTopBar: boolean,
  hasTabBar: boolean,
  desktopMode: boolean,
  inDevice: boolean
) => {
  const safeAreaTop = inDevice
    ? 53
    : +getComputedStyle(document.documentElement)
        .getPropertyValue("--top")
        .replace("px", "");
  const safeAreaBottom = inDevice
    ? 33
    : +getComputedStyle(document.documentElement)
        .getPropertyValue("--bottom")
        .replace("px", "");
  const topBarHeight = desktopMode ? 64 : 44;
  const tabBarHeight = desktopMode ? 0 : 49;
  switch (value) {
    case ISize.buttonHeight:
    case ISize.fieldHeight:
      return 50;
    case ISize.cornersRadius:
      return 8;
    case ISize.max:
      return 1000;
    case ISize.topScreenMargins:
      return safeAreaTop + (hasTopBar ? topBarHeight : 0);
    case ISize.bottomScreenMargins:
      return safeAreaBottom + (hasTabBar ? tabBarHeight : 0);
    case ISize.thinSeparator:
      return 1;
    case ISize.horizontalMargin:
      return 24;
    case ISize.horizontalSpacing:
    case ISize.verticalSpacing:
      return 16;
    case ISize.trailingScreenMargins:
    case ISize.leadingScreenMargins:
    case null:
      return 0;
    case ILocalSize.topBarHeight:
      return topBarHeight;
    case ILocalSize.tabBarHeight:
      return tabBarHeight;
    default:
      return +value;
  }
};

export enum TextAlign {
  START = "start",
  CENTER = "center",
  END = "end",
}

export const getCSSTextAlignment = (val?: TextAlignment) => {
  switch (val) {
    case TextAlignment.leading:
      return TextAlign.START;
    case TextAlignment.center:
      return TextAlign.CENTER;
    case TextAlignment.trailing:
      return TextAlign.END;
    default:
      return TextAlign.START;
  }
};

export const getText = (language: string, text?: LocalizedContent) => {
  let key = "key";
  let value = "";
  let hasLocale = false;
  if (text) {
    key = text.key;
    hasLocale = text.locales[language] !== undefined;
    if (hasLocale) {
      value = text.locales[language];
    } else {
      value = key;
    }
  }
  return { key, value, hasLocale };
};

export const getTextValue = async (
  language: string,
  getVariableValue: GetVariableValue,
  refresh?: Refresh,
  text?: LocalizedContent
) => {
  let { value, hasLocale } = getText(language, text);
  if (hasLocale && text) {
    const { values } = text;
    if (values) {
      for (let i = 0; i < values.length; i++) {
        const index = i + 1;
        const valueValue = await getVariableValue(
          { source: values[i] },
          refresh
        );
        value = value.replace(
          `$${index}`,
          valueValue !== null && valueValue !== undefined ? valueValue : ""
        );
      }
    }
  }
  return value;
};

const cloudinaryUrl = "https://res.cloudinary.com/codeplatform/image/upload";

export const getMediaResourceUrl = async (
  getVariableValue: GetVariableValue,
  refresh?: Refresh,
  media?: ImageVariable,
  imageColor?: ColorVariable,
  element?: HTMLElement
) => {
  const mediaValue = await getVariableValue(
    { ...media, imageConstant: media?.constant },
    refresh
  );
  let url: string = mediaValue?.url || "";
  if (url.startsWith(cloudinaryUrl)) {
    url = url.replace(".svg", ".png");
    if (imageColor) {
      const imageColorValue = await getVariableValue(
        { ...imageColor, colorConstant: imageColor.constant },
        refresh
      );
      url = url.replace(
        cloudinaryUrl,
        `${cloudinaryUrl}/e_colorize,co_rgb:${imageColorValue.slice(1)}`
      );
    }
    if (element) {
      const w = element
        ? element.clientWidth || +element.style.width.replace("px", "") || 0
        : 0;
      const h = element
        ? element.clientHeight || +element.style.height.replace("px", "") || 0
        : 0;
      const urlParts = url.split("/");
      const crop = urlParts.find((el) => el.includes("c_crop"));
      if (crop) {
        const cropIndex = urlParts.indexOf(crop);
        const cropParts = crop.split(",");
        const width = cropParts[2]?.replace("w_", "");
        const height = cropParts[1]?.replace("h_", "");
        const widthScale = +width / w >= 2 ? 1 : Math.round(w / +width) + 1;
        const heightScale = +height / h >= 2 ? 1 : Math.round(h / +height) + 1;
        const isW = widthScale > heightScale;
        const scale = isW ? widthScale : heightScale;
        if (scale > 1) {
          urlParts.splice(
            cropIndex + 1,
            0,
            `${isW ? "w" : "h"}_${scale}.0,c_scale`
          );
        }
        url = urlParts.join("/");
      } else if (mediaValue?.width && mediaValue?.height) {
        const { width, height } = mediaValue;
        const widthScale = +width / w >= 2 ? 1 : Math.round(w / +width) + 1;
        const heightScale = +height / h >= 2 ? 1 : Math.round(h / +height) + 1;
        const isW = widthScale > heightScale;
        const scale = isW ? widthScale : heightScale;
        if (scale > 1) {
          url = url.replace(
            cloudinaryUrl,
            `${cloudinaryUrl}/${isW ? "w" : "h"}_${scale}.0,c_scale`
          );
        }
      }
    }
  }
  return url;
};

export const findComponent = (
  screenConfig: Screen,
  propName: keyof ScreenComponent,
  value: string
): ScreenComponent | undefined => {
  const { components, topBar } = screenConfig;
  const find = (components: ScreenComponent[]): ScreenComponent | undefined => {
    let component: ScreenComponent | undefined;
    for (const el of components) {
      if (el[propName] === value) {
        component = el;
      } else if (el.subComponents) {
        component = find(el.subComponents);
      }
      if (component) {
        return component;
      }
    }
  };
  return find([
    ...(components || []),
    ...(topBar?.leadingComponents || []),
    ...(topBar?.headlineComponents || []),
    ...(topBar?.trailingComponents || []),
  ]);
};

export const getElementIdFromConfig = (component: ScreenComponent) =>
  `${component.componentType}.${component.name || component.componentType}.${
    component.id
  }`;

export const coordinateHandler = (
  coordinate: CoordinateConstant,
  center: { lat: number; lng: number }
) => {
  const lat = coordinate.latitude;
  const lng = coordinate.longitude;
  if (lat) {
    center.lat = +lat;
  }
  if (lng) {
    center.lng = +lng;
  }
  return center;
};

export const readableError = (err: string) =>
  err.split(" (")[0]?.replace("Firebase: ", "");

export const getInputParameters = (inputParameters?: ScreenParameter[]) => {
  const inputParameter = inputParameters?.find((el) => el.required);
  const inoutInputParameters = inputParameters?.filter((el) => el.inout);
  const searchInputParameters = inputParameters?.filter(
    (el) => !el.required && !el.inout
  );
  return { inputParameter, inoutInputParameters, searchInputParameters };
};
