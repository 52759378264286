import { F } from "../context";
import {
  generateRefreshKey,
  GetVariable,
  GetVariableValue,
  Update,
} from "../hooks";
import {
  BooleanVariable,
  BrandingColorType,
  BrandingFontStyle,
  BrandingThemeType,
  ILocalSize,
  ISize,
  LocalizedContent,
  Screen,
  ScreenTopBar,
  setColors,
  setComponents,
} from "./index";

export const createTopBar = async (
  f: F,
  id: string,
  screen: HTMLElement,
  fontFamily: string,
  fontStyles: BrandingFontStyle[],
  theme: BrandingThemeType,
  desktopMode: boolean,
  navigate: (pathname: string) => void,
  navigateBack: () => void,
  openDialog: (dialog: string) => void,
  closeDialog: () => void,
  closeAllDialogs: () => void,
  screens: Screen[],
  screenConfig: Screen,
  getVariableValue: GetVariableValue,
  getVariable: GetVariable,
  update: Update,
  topBar?: ScreenTopBar,
  showBackButton?: BooleanVariable,
  screenTitle?: LocalizedContent
) => {
  const {
    backgroundColor = { constant: `@${BrandingColorType.background}` },
    dialog,
  } = screenConfig;
  const height = await getVariableValue({
    numberConstant: ISize.topScreenMargins,
  });
  const topBarHeight = await getVariableValue({
    numberConstant: ILocalSize.topBarHeight,
  });
  const surfaceVariant = await getVariableValue({
    colorConstant: `@${BrandingColorType.surfaceVariant}`,
  });
  const onBackground = await getVariableValue({
    colorConstant: `@${BrandingColorType.onBackground}`,
  });

  const screenTopBar = document.createElement("div");
  screen.appendChild(screenTopBar);

  screenTopBar.id = id;
  screenTopBar.style.zIndex = "1";
  screenTopBar.style.position = "fixed";
  screenTopBar.style.left = "0";
  screenTopBar.style.top = "0";
  screenTopBar.style.width = "100%";
  screenTopBar.style.height = `${height}px`;
  screenTopBar.style.padding = `${height - topBarHeight}px 12px 0`;
  screenTopBar.style.borderBottom = `1px solid ${surfaceVariant}`;
  screenTopBar.style.boxSizing = "border-box";
  screenTopBar.style.display = "flex";
  screenTopBar.style.alignItems = "center";
  screenTopBar.style.flexShrink = "0";

  if (desktopMode) {
    screenTopBar.style.position = "sticky";
    screenTopBar.style.marginBottom = `-${height}px`;
  }

  await setColors(
    id,
    screenTopBar,
    getVariableValue,
    topBar?.backgroundColor || backgroundColor
  );

  const leading = document.createElement("div");
  leading.style.position = "relative";
  leading.style.width = "calc(100% / 3)";
  leading.style.minWidth = "max-content";
  leading.style.minHeight = "22px";
  leading.style.display = "flex";
  leading.style.alignItems = "center";
  leading.style.whiteSpace = "nowrap";
  screenTopBar.appendChild(leading);

  const headline = document.createElement("div");
  headline.style.position = "relative";
  headline.style.width = "calc(100% / 3)";
  headline.style.minWidth = "max-content";
  headline.style.minHeight = "22px";
  headline.style.display = "flex";
  headline.style.alignItems = "center";
  headline.style.justifyContent = "center";
  headline.style.whiteSpace = "nowrap";
  screenTopBar.appendChild(headline);

  const trailing = document.createElement("div");
  trailing.style.position = "relative";
  trailing.style.width = "calc(100% / 3)";
  trailing.style.minWidth = "max-content";
  trailing.style.minHeight = "22px";
  trailing.style.display = "flex";
  trailing.style.alignItems = "center";
  trailing.style.justifyContent = "flex-end";
  trailing.style.whiteSpace = "nowrap";
  screenTopBar.appendChild(trailing);

  const showBackButtonValue = await getVariableValue({
    ...showBackButton,
    booleanConstant: showBackButton?.constant,
  });
  if (showBackButtonValue) {
    const backButton = document.createElement("div");
    backButton.style.display = "flex";
    backButton.innerHTML = `
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0659 12.4121H5.50586" stroke="${onBackground}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.7859 19.4121L5.50586 12.4121L12.7859 5.41211" stroke="${onBackground}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `;
    leading.appendChild(backButton);

    backButton.className = "clickable";
    backButton.onclick = () => (dialog ? closeDialog() : navigateBack());
  }

  if (topBar?.leadingComponents?.length) {
    await setComponents(
      f,
      topBar.leadingComponents,
      leading,
      fontFamily,
      fontStyles,
      theme,
      false,
      false,
      desktopMode,
      navigate,
      navigateBack,
      openDialog,
      closeDialog,
      closeAllDialogs,
      screens,
      screenConfig,
      getVariableValue,
      getVariable,
      update,
      () => {},
      0,
      () => {}
    );
  }

  if (topBar?.headlineComponents?.length) {
    await setComponents(
      f,
      topBar.headlineComponents,
      headline,
      fontFamily,
      fontStyles,
      theme,
      false,
      false,
      desktopMode,
      navigate,
      navigateBack,
      openDialog,
      closeDialog,
      closeAllDialogs,
      screens,
      screenConfig,
      getVariableValue,
      getVariable,
      update,
      () => {},
      0,
      () => {}
    );
  } else if (screenTitle) {
    const title = document.createElement("div");
    title.style.fontFamily = fontFamily;
    title.style.fontWeight = "600";
    title.style.fontSize = "17px";
    title.style.lineHeight = "130%";
    title.style.color = onBackground;
    const screenTitleRefresh = async () => {
      title.innerHTML = await getVariableValue(
        { textConstant: screenTitle },
        { [generateRefreshKey(id, "screenTitle")]: screenTitleRefresh }
      );
    };
    await screenTitleRefresh();
    headline.appendChild(title);
  }

  if (topBar?.trailingComponents?.length) {
    await setComponents(
      f,
      topBar.trailingComponents,
      trailing,
      fontFamily,
      fontStyles,
      theme,
      false,
      false,
      desktopMode,
      navigate,
      navigateBack,
      openDialog,
      closeDialog,
      closeAllDialogs,
      screens,
      screenConfig,
      getVariableValue,
      getVariable,
      update,
      () => {},
      0,
      () => {}
    );
  }
};
