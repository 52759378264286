const accepted_cookies = "accepted_cookies";

export const checkIdfaAndCookieTrackingEnabled = () =>
  document.cookie.includes(accepted_cookies);

export const enableIdfaAndCookieTracking = () => {
  if (navigator.cookieEnabled) {
    document.cookie = `${accepted_cookies}=yes;`;
    return;
  }
  throw new Error(
    "User Cookies Permission Denied. Enable Cookies in browser settings."
  );
};
