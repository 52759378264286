import { fetchAndActivate } from "firebase/remote-config";

import { F } from "../context";
import {
  GetVariable,
  setInputParameters,
  setLocalVariables,
  Update,
} from "../hooks";
import {
  BarComponentType,
  BrandingThemeType,
  ComponentType,
  Config,
  createLogo,
  createScreen,
  createScreenCorners,
  createTabBar,
  createTopBar,
  readableError,
  runActions,
  Screen,
  ScreenSize,
  setComponents,
  setTabs,
  startLoader,
  stopLoader,
} from "./index";

const scroll: { [key: string]: () => void } = {};
const chatsLimits: { [key: string]: number } = {};

export const getScreen = async (
  f: F,
  currentScreen: Screen,
  config: Config,
  parentElement: HTMLElement,
  desktopMode: boolean,
  theme: BrandingThemeType,
  launchOpen: boolean,
  setLaunchOpen: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: (pathname: string) => void,
  navigateBack: () => void,
  openDialog: (dialog: string) => void,
  closeDialog: () => void,
  closeAllDialogs: () => void,
  getVariable: GetVariable,
  update: Update,
  email?: string,
  token?: string,
  leading?: boolean,
  trailing?: boolean,
  inputParameterValue?: string
) => {
  startLoader();
  const {
    auth,
    remoteConfig,
    waitProfile,
    authHandler,
    emailKey,
    oauthStateKey,
  } = f;
  const screenConfig = JSON.parse(JSON.stringify(currentScreen)) as Screen;
  const {
    id,
    screenName,
    size,
    components = [],
    topBar,
    showTopBar,
    showBackButton,
    screenTitle,
    onScreenVisitActions,
    inputParameters,
    localVariables,
    dialog,
  } = screenConfig;
  const {
    screens = [],
    branding: {
      typography: { iosDefaultFontFamily: fontFamily, fontStyles = [] },
    },
    tabBars = [],
  } = config;
  const tabBar = tabBars.find((el) =>
    el.tabs?.find((el) => el.screen === screenName)
  );
  const screenId = `screen.${screenName}.${id}`;
  const tabBarId = `${BarComponentType.tabBar}.${tabBar?.name}.${tabBar?.id}`;
  const topBarId = `${BarComponentType.topBar}.top bar.${id}-${BarComponentType.topBar}`;
  screenConfig.id = inputParameterValue ? id + inputParameterValue : id;
  const getVariableValue = getVariable(screenConfig);
  const runOnScreenVisitActions = () =>
    onScreenVisitActions &&
    runActions(
      f,
      id,
      onScreenVisitActions,
      navigate,
      navigateBack,
      openDialog,
      closeDialog,
      closeAllDialogs,
      screens,
      screenConfig,
      getVariableValue,
      getVariable,
      update
    );
  if (inputParameters) {
    await setInputParameters(
      id,
      inputParameters,
      getVariableValue,
      inputParameterValue
    );
  }
  if (localVariables) {
    await setLocalVariables(id, getVariableValue, localVariables);
  }
  if (!launchOpen) {
    const cancelled = await runOnScreenVisitActions();
    if (cancelled) {
      return;
    }
  }
  if (!trailing) {
    parentElement.innerHTML = "";
  }
  const screen = await createScreen(
    screenId,
    screenConfig,
    parentElement,
    getVariableValue,
    size,
    !!tabBar,
    leading,
    trailing
  );
  const customScroll =
    desktopMode &&
    size === ScreenSize.responsive &&
    !leading &&
    !trailing &&
    components.length === 1 &&
    (components[0].componentType === ComponentType.scroll ||
      components[0].componentType === ComponentType.list);
  if (customScroll) {
    parentElement.style.removeProperty("height");
    screen.style.removeProperty("height");
  } else {
    parentElement.style.height = "100%";
  }
  if (desktopMode) {
    createScreenCorners(screen);
    if (!trailing && !dialog) {
      await createLogo(
        screen,
        fontFamily,
        size === ScreenSize.compact && !tabBar,
        getVariableValue,
        email || token
          ? undefined
          : () => {
              setLaunchOpen(true);
              navigate("/");
            }
      );
    }
  }
  const showTopBarValue = await getVariable(screenConfig)({
    ...showTopBar,
    booleanConstant: showTopBar?.constant,
  });
  if (showTopBarValue) {
    await createTopBar(
      f,
      topBarId,
      screen,
      fontFamily,
      fontStyles,
      theme,
      desktopMode,
      navigate,
      navigateBack,
      openDialog,
      closeDialog,
      closeAllDialogs,
      screens,
      screenConfig,
      getVariableValue,
      getVariable,
      update,
      topBar,
      showBackButton,
      screenTitle
    );
  }
  if (tabBar) {
    const screenTabBar = await createTabBar(
      tabBarId,
      tabBar,
      screen,
      getVariableValue
    );
    await setTabs(
      screenName,
      tabBar,
      screenTabBar,
      fontFamily,
      desktopMode,
      navigate,
      getVariableValue
    );
  }
  if (components.length) {
    await setComponents(
      f,
      components,
      screen,
      fontFamily,
      fontStyles,
      theme,
      customScroll,
      true,
      desktopMode,
      navigate,
      navigateBack,
      openDialog,
      closeDialog,
      closeAllDialogs,
      screens,
      screenConfig,
      getVariableValue,
      getVariable,
      update,
      (func) => (scroll[screenConfig.id] = func),
      chatsLimits[screenConfig.id] || 30,
      (limit) => (chatsLimits[screenConfig.id] = limit)
    );
    scroll[screenConfig.id]?.();
    if (desktopMode && !scroll[screenConfig.id]) {
      window.scrollTo({ top: 0 });
    }
  }
  stopLoader();
  if (launchOpen) {
    try {
      await fetchAndActivate(remoteConfig);
      await new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(
          (user) => {
            unsubscribe();
            resolve(user);
          },
          (err) => {
            unsubscribe();
            reject(err);
          }
        );
      });
      if (email && auth.currentUser?.email !== email) {
        window.parent.postMessage("NEED_TOKEN", "*");
        return;
      } else if (token) {
        await auth.signInWithCustomToken(token);
        await waitProfile(getVariableValue);
      } else if (auth.isSignInWithEmailLink(window.location.href)) {
        const email = localStorage.getItem(emailKey);
        if (email) {
          await auth.signInWithEmailLink(email, window.location.href);
          localStorage.removeItem(emailKey);
          await waitProfile(getVariableValue);
        }
      } else if (window.location.pathname === "/auth/handler") {
        const oauthState = localStorage.getItem(oauthStateKey);
        if (oauthState) {
          const obj = JSON.parse(oauthState);
          const state = Object.keys(obj)[0];
          const params = window.location.hash
            .slice(1)
            .split("&")
            .map((el) => el.split("="))
            .map((el) => ({ [el[0]]: decodeURIComponent(el[1]) }))
            .reduce((a, b) => ({ ...a, ...b }), {});
          if (params.state === state) {
            if (!params.error) {
              await authHandler(params);
            }
            localStorage.removeItem(oauthStateKey);
            navigate(obj[state]);
            return;
          } else {
            throw new Error("Oauth error.");
          }
        }
      }
    } catch (err: any) {
      alert(readableError(err.message));
    }
    await runOnScreenVisitActions();
    setLaunchOpen(false);
  }
};
