import { generateRefreshKey, GetVariableValue } from "../hooks";
import {
  BooleanVariable,
  NumberVariable,
  progressActiveAttribute,
  progressTrackAttribute,
  progressPassedAttribute,
} from "./index";

export const carouselHandler = async (
  id: string,
  carousel: HTMLElement,
  getVariableValue: GetVariableValue,
  value?: NumberVariable,
  duration?: NumberVariable,
  loop?: BooleanVariable,
  indexInList?: number
) => {
  const slides = Array.from(
    carousel.querySelectorAll(":scope > div")
  ) as HTMLElement[];
  const progressTracks = Array.from(
    carousel.querySelectorAll(`:scope > i > i > i[${progressTrackAttribute}]`)
  ) as SVGRectElement[];
  if (slides.length && progressTracks.length) {
    let timer: NodeJS.Timeout | null;
    const lastIndex = slides.length - 1;
    const durationValue = await getVariableValue({
      ...duration,
      numberConstant: duration?.constant,
    });
    const loopValue = await getVariableValue({
      ...loop,
      booleanConstant: loop?.constant,
    });
    slides.forEach(
      (el, i) =>
        (el.onclick = (e) => {
          if (timer) {
            clearTimeout(timer);
          }
          slides.slice(0, i).forEach((el) => (el.hidden = true));
          slides.slice(i + 1).forEach((el) => (el.hidden = true));
          slides[i].removeAttribute("hidden");
          progressTracks.slice(0, i).forEach((el) => {
            el.setAttribute(progressPassedAttribute, "true");
            el.removeAttribute(progressActiveAttribute);
          });
          progressTracks.slice(i + 1).forEach((el) => {
            el.removeAttribute(progressPassedAttribute);
            el.removeAttribute(progressActiveAttribute);
          });
          progressTracks[i].removeAttribute(progressPassedAttribute);
          progressTracks[i].setAttribute(progressActiveAttribute, "true");
          const prev = () => {
            if (slides[i - 1]) {
              slides[i - 1].click();
            } else if (loopValue) {
              slides[lastIndex].click();
            }
          };
          const next = () => {
            if (slides[i + 1]) {
              slides[i + 1].click();
            } else if (loopValue) {
              slides[0].click();
            }
          };
          if (e.offsetX) {
            if (e.offsetX < slides[i].clientWidth / 5) {
              prev();
            } else {
              next();
            }
          } else {
            if (!document.getElementById(carousel.id)) {
              return;
            } else if (durationValue) {
              timer = setTimeout(next, durationValue * 1000);
            }
          }
        })
    );
    const valueRefresh = async () => {
      const valueValue = await getVariableValue(
        { ...value, numberConstant: value?.constant },
        { [generateRefreshKey(id, "value", indexInList)]: valueRefresh }
      );
      const currentValue = valueValue || 0;
      slides[currentValue > lastIndex ? 0 : currentValue]?.click();
    };
    await valueRefresh();
  }
};
