import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import slugify from "react-slugify";

import { FirebaseContext } from "./context";
import { Dialog } from "./Dialog";
import { useVariable } from "./hooks";
import { BrandingThemeType, Config, Screen, getScreen } from "./utils";

interface Props {
  inDevice: boolean;
  language: string;
  theme: BrandingThemeType;
  desktopMode: boolean;
  wideDesktopMode: boolean;
  config: Config;
  screens: Screen[];
  launchScreen: Screen;
  email?: string;
  token?: string;
}

export const Page: React.FC<Props> = ({
  inDevice,
  language,
  theme,
  desktopMode,
  wideDesktopMode,
  config,
  screens,
  launchScreen,
  email,
  token,
}) => {
  const screenParentElementRef = useRef<HTMLDivElement>(null);
  const { f } = useContext(FirebaseContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [launchOpen, setLaunchOpen] = useState(true);
  const [dialog, setDialog] = useState<string | null>(null);

  const pages = pathname.slice(1).split("/-/");
  const [page, encodedInputParameterValue] = pages[pages.length - 1].split("/");
  const inputParameterValue = encodedInputParameterValue
    ? decodeURIComponent(encodedInputParameterValue)
    : undefined;

  const originalScreen = useMemo(
    () => screens.find((el) => slugify(el.screenName) === page),
    [page]
  );

  const screen = useMemo(
    () => (launchOpen ? launchScreen : originalScreen),
    [launchOpen, originalScreen]
  );

  const leadingPaneScreenName = screen?.experimental?.leadingPane?.screenName;
  const leadingPaneScreen = useMemo(
    () =>
      wideDesktopMode &&
      screens.find((el) => el.screenName === leadingPaneScreenName),
    [wideDesktopMode, leadingPaneScreenName]
  );

  const trailingPaneScreenName = screen?.experimental?.trailingPane?.screenName;
  const trailingPaneScreen = useMemo(
    () =>
      wideDesktopMode &&
      screens.find((el) => el.screenName === trailingPaneScreenName),
    [wideDesktopMode, trailingPaneScreenName]
  );

  const { getVariable, update } = useVariable(
    f,
    inDevice,
    desktopMode,
    config,
    language,
    theme,
    screen,
    inputParameterValue
  );

  const navigateBack = () => navigate(`/${pages.slice(0, -1).join("/-/")}`);

  const closeDialog = () => setDialog(null);

  const closeAllDialogs = closeDialog;

  useEffect(() => {
    const screenParentElement = screenParentElementRef.current;
    if (screenParentElement) {
      const generateScreen = async () => {
        if (leadingPaneScreen) {
          await getScreen(
            f,
            leadingPaneScreen,
            config,
            screenParentElement,
            desktopMode,
            theme,
            launchOpen,
            setLaunchOpen,
            (pathname) =>
              navigate(
                pathname.startsWith("/")
                  ? pathname
                  : `/${pages.slice(0, -1).join("/-/")}/${pathname}`
              ),
            navigateBack,
            setDialog,
            closeDialog,
            closeAllDialogs,
            getVariable,
            update,
            email,
            token,
            true
          );
        }
        if (screen) {
          await getScreen(
            f,
            screen,
            config,
            screenParentElement,
            desktopMode,
            theme,
            launchOpen,
            setLaunchOpen,
            navigate,
            navigateBack,
            setDialog,
            closeDialog,
            closeAllDialogs,
            getVariable,
            update,
            email,
            token,
            !!trailingPaneScreen,
            !!leadingPaneScreen,
            inputParameterValue
          );
        }
        if (trailingPaneScreen) {
          await getScreen(
            f,
            trailingPaneScreen,
            config,
            screenParentElement,
            desktopMode,
            theme,
            launchOpen,
            setLaunchOpen,
            navigate,
            navigateBack,
            setDialog,
            closeDialog,
            closeAllDialogs,
            getVariable,
            update,
            email,
            token,
            false,
            true
          );
        }
      };
      generateScreen();
    }
  }, [
    leadingPaneScreen,
    screen,
    trailingPaneScreen,
    inputParameterValue,
    language,
    theme,
    inDevice,
    desktopMode,
  ]);

  useEffect(() => {
    closeAllDialogs();
  }, [pathname]);

  return (
    <>
      <div ref={screenParentElementRef} />
      {dialog && (
        <Dialog
          inDevice={inDevice}
          language={language}
          theme={theme}
          desktopMode={desktopMode}
          config={config}
          screens={screens}
          page={dialog}
          launchOpen={launchOpen}
          setLaunchOpen={setLaunchOpen}
          navigate={navigate}
          navigateBack={navigateBack}
          closeDialog={closeDialog}
          closeAllDialogs={closeAllDialogs}
          getVariable={getVariable}
          update={update}
        />
      )}
    </>
  );
};
