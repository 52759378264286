import { generateRefreshKey, GetVariableValue } from "../hooks";
import {
  BackgroundGradient,
  BackgroundGradientDirection,
  ColorVariable,
  ImageConstant,
} from "./index";

export const setColors = async (
  id: string,
  element: HTMLElement,
  getVariableValue: GetVariableValue,
  backgroundColor?: ColorVariable,
  backgroundGradient?: BackgroundGradient,
  backgroundPattern?: ImageConstant,
  textColor?: ColorVariable,
  borderColor?: ColorVariable,
  indexInList?: number
) => {
  if (backgroundColor) {
    const backgroundColorRefresh = async () => {
      element.style.backgroundColor = await getVariableValue(
        { ...backgroundColor, colorConstant: backgroundColor.constant },
        {
          [generateRefreshKey(id, "backgroundColor", indexInList)]:
            backgroundColorRefresh,
        }
      );
    };
    await backgroundColorRefresh();
  }

  if (backgroundGradient) {
    const { direction, start, end } = backgroundGradient;
    const startColor = await getVariableValue({ colorConstant: start });
    const endColor = await getVariableValue({ colorConstant: end });
    element.style.backgroundImage =
      direction === BackgroundGradientDirection.radial
        ? `radial-gradient(${startColor}, ${endColor})`
        : `linear-gradient(to ${
            direction === BackgroundGradientDirection.vertical
              ? "bottom"
              : "right"
          }, ${startColor}, ${endColor})`;
  }

  if (backgroundPattern) {
    const mediaValue = await getVariableValue({
      imageConstant: backgroundPattern,
    });
    const url = mediaValue?.url || "";
    element.style.backgroundImage = `url(${url})`;
    element.style.backgroundPosition = "center";
    element.style.backgroundSize = "cover";
  }

  if (textColor) {
    const textColorRefresh = async () => {
      element.style.color = await getVariableValue(
        { ...textColor, colorConstant: textColor.constant },
        { [generateRefreshKey(id, "textColor", indexInList)]: textColorRefresh }
      );
    };
    await textColorRefresh();
  }

  if (borderColor) {
    const borderColorRefresh = async () => {
      element.style.borderColor = await getVariableValue(
        { ...borderColor, colorConstant: borderColor.constant },
        {
          [generateRefreshKey(id, "borderColor", indexInList)]:
            borderColorRefresh,
        }
      );
    };
    await borderColorRefresh();
  }
};
