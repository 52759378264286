import { GetVariableValue } from "../hooks";
import { BrandingColorType, Screen, ScreenSize, setColors } from "./index";

export const createScreen = async (
  id: string,
  screenConfig: Screen,
  parentElement: HTMLElement,
  getVariableValue: GetVariableValue,
  size: ScreenSize,
  hasTabBar: boolean,
  leading?: boolean,
  trailing?: boolean
) => {
  const {
    backgroundColor = { constant: `@${BrandingColorType.background}` },
    backgroundGradient,
    backgroundPattern,
  } = screenConfig;

  const screen = document.createElement("div");
  parentElement.appendChild(screen);

  screen.id = id;
  screen.style.width = "100%";
  screen.style.height = "100%";
  screen.style.display = "flex";
  screen.style.flexDirection = "column";
  screen.className = leading
    ? "leading"
    : trailing
    ? "trailing"
    : size === ScreenSize.responsive && hasTabBar
    ? `${size}WithTabBar`
    : size;

  await setColors(
    id,
    screen,
    getVariableValue,
    backgroundColor,
    backgroundGradient,
    backgroundPattern
  );

  return screen;
};

export const createScreenCorners = (screen: HTMLElement) =>
  [
    "corner-topLeft",
    "corner-topRight",
    "corner-bottomRight",
    "corner-bottomLeft",
  ].forEach((id) => {
    const corner = document.createElement("div");
    corner.id = id;
    screen.appendChild(corner);
  });
