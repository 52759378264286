import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/installations";
import "firebase/compat/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getRemoteConfig, getValue } from "firebase/remote-config";
import { useEffect } from "react";

import firebaseWebConfig from "../firebase-web-config.json";
import { getParams, GetVariableValue } from "../hooks";
import {
  generateFirestoreId,
  profilesId,
  readableError,
  VariableSourceType,
  VariableTransformTransform,
} from "../utils";
import { FirebaseContext, FP, FV, FNP } from "./index";

export interface FirebaseConfig {
  [key: string]: string;
}

interface Props {
  children: React.ReactNode;
  webFirebaseConfig?: FirebaseConfig;
  firestorePrefix?: string;
}

export const FirebaseProvider: React.FC<Props> = ({
  children,
  webFirebaseConfig,
  firestorePrefix = "",
}) => {
  const emailKey = "email";
  const oauthStateKey = "oauthState";

  const firebaseConfig =
    webFirebaseConfig || (firebaseWebConfig as FirebaseConfig);

  const app = firebase.initializeApp(firebaseConfig);

  const auth = firebase.auth();

  const firestore = firebase.firestore(app);

  const storage = firebase.storage(app);

  const remoteConfig = getRemoteConfig(app);

  const messaging = getMessaging(app);

  onMessage(messaging, (payload) => {
    const { title = "", body, icon } = payload.notification || {};
    new Notification(title, { body, icon });
  });

  const googleProvider = new firebase.auth.GoogleAuthProvider();
  googleProvider.setCustomParameters({ prompt: "select_account" });

  const signInWithGoogle = async () => {
    await auth.signInWithPopup(googleProvider);
  };

  const createUserWithEmailAndPassword: FP = async (...props) => {
    const { email = "", password = "" } = await getParams(...props);
    await auth.createUserWithEmailAndPassword(email, password);
  };

  const signInWithEmailAndPassword: FP = async (...props) => {
    const { email = "", password = "" } = await getParams(...props);
    await auth.signInWithEmailAndPassword(email, password);
  };

  const sendPasswordResetEmail: FV = async (variable, getVariableValue) => {
    const email = (await getVariableValue(variable)) || "";
    await auth
      .sendPasswordResetEmail(email)
      .then(() => alert(`Link was sent to ${email}`));
  };

  const sendSignInLinkToEmail: FV = async (variable, getVariableValue) => {
    const email = (await getVariableValue(variable)) || "";
    await auth
      .sendSignInLinkToEmail(email, {
        url: window.location.origin,
        handleCodeInApp: true,
      })
      .then(() => localStorage.setItem(emailKey, email));
  };

  const reSendSignInLinkToEmail: FV = async (...props) => {
    const result = window.confirm("Trouble getting email? Resend?");
    if (result) {
      await sendSignInLinkToEmail(...props);
    }
  };

  const waitProfile = async (getVariableValue: GetVariableValue) => {
    const check = () =>
      getVariableValue({
        source: {
          collection: { name: profilesId },
          selector: {
            source: {
              type: VariableSourceType.globalVariable,
              variableName: "currentUserId",
            },
          },
          transforms: [{ transform: VariableTransformTransform.exists }],
          type: VariableSourceType.collection,
        },
      });
    let ready = await check();
    while (!ready) {
      await new Promise((r) => setTimeout(r, 1000));
      ready = await check();
    }
  };

  const getAssetRecord = (file: any) =>
    new Promise(async (resolve) => {
      const { name: title, type } = file;
      const [resourceType, format] = type.split("/");
      const storageRef = storage.ref(
        `uploads/${auth.currentUser?.uid}/${generateFirestoreId()}/${title}`
      );
      storageRef
        .put(file)
        .then(() =>
          storageRef.getDownloadURL().then((url) =>
            resolve({
              title,
              folder: "~",
              url,
              resourceType,
              format,
              width: null,
              height: null,
              bytes: null,
              duration: null,
              version: null,
              signature: null,
              error: null,
            })
          )
        )
        .catch((err) => alert(readableError(err.message)))
        .finally(() => resolve(null));
    });

  const httpClient = axios.create({
    headers: { "Content-Type": "application/json" },
  });

  httpClient.interceptors.request.use(
    async (config) => {
      const accessToken = await auth.currentUser?.getIdToken();
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );

  httpClient.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (err.response) {
        const originalConfig = err.config;
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          const accessToken = await auth.currentUser?.getIdToken(true);
          if (accessToken) {
            httpClient.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${accessToken}`;
          }
          return httpClient(originalConfig);
        }
      }
      return Promise.reject(err);
    }
  );

  const getApiUrl = async () => {
    const apiUrl = getValue(remoteConfig, "apiUrl").asString();
    if (!apiUrl) {
      throw new Error("Backend is not deployed.");
    }
    return apiUrl;
  };

  const getOauthClientId = async () => {
    const oauthClientId = getValue(remoteConfig, "oauthClientId").asString();
    if (!oauthClientId) {
      throw new Error("oauthClientId not found.");
    }
    return oauthClientId;
  };

  const getInstallationId = () => app.installations().getId();

  const getNotificationToken = () =>
    getToken(messaging, { vapidKey: firebaseConfig.vapidKey });

  const setNotificationToken = async () => {
    const apiUrl = await getApiUrl();
    const installationId = await getInstallationId();
    const token = await getNotificationToken();
    await httpClient.post(`${apiUrl}/v1/auth/devices`, {
      id: installationId,
      fcmToken: token,
      platform: "web",
      name: window.navigator.userAgent,
    });
  };

  const deleteNotificationToken = async () => {
    const apiUrl = await getApiUrl();
    const installationId = await getInstallationId();
    await httpClient.delete(`${apiUrl}/v1/auth/devices/${installationId}`);
  };

  const checkNotificationsEnabled = async () => {
    if ("Notification" in window && Notification.permission === "granted") {
      try {
        await setNotificationToken();
        return true;
      } catch {
        return false;
      }
    } else {
      return false;
    }
  };

  const enableNotifications = async () => {
    if ("Notification" in window) {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        await setNotificationToken();
        return;
      }
    }
    throw new Error(
      "User Notifications Permission Denied. Enable Notifications in browser settings."
    );
  };

  const logout = async () => {
    try {
      await deleteNotificationToken();
    } catch {}
    await auth.signOut();
  };

  const runFlow: FNP = async (name, ...props) => {
    const apiUrl = await getApiUrl();
    const data = await getParams(...props);
    return httpClient.post(`${apiUrl}/v1/flows/${name}`, data);
  };

  const search = async (url: string) => {
    const apiUrl = await getApiUrl();
    return httpClient.get(`${apiUrl}/v1/${url}`);
  };

  const oauth: FP = async (...props) => {
    const { provider = "", scopes = [] } = await getParams(...props);
    if (provider === "google") {
      const oauthClientId = await getOauthClientId();
      const params: { [key: string]: string } = {
        client_id: oauthClientId,
        redirect_uri: `${window.location.origin}/auth/handler`,
        response_type: "token",
        scope: scopes.join(" "),
        include_granted_scopes: "true",
        state: generateFirestoreId(),
      };
      const oauthState = JSON.stringify({
        [params.state]: window.location.pathname,
      });
      localStorage.setItem(oauthStateKey, oauthState);
      window.location.replace(
        `https://accounts.google.com/o/oauth2/v2/auth?${Object.keys(params)
          .map((el) => `${el}=${encodeURIComponent(params[el])}`)
          .join("&")}`
      );
    }
  };

  const authHandler = async (data: any) => {
    const apiUrl = await getApiUrl();
    return httpClient.post(`${apiUrl}/v1/auth/handler`, data);
  };

  useEffect(() => {
    const googleMaps = document.getElementById(
      "googleMaps"
    ) as HTMLScriptElement;
    googleMaps.setAttribute(
      "src",
      `https://maps.googleapis.com/maps/api/js?key=${firebaseConfig.apiKey}&loading=async&callback=Function.prototype`
    );
  }, []);

  return (
    <FirebaseContext.Provider
      value={{
        f: {
          firestorePrefix,
          auth,
          firestore,
          remoteConfig,
          signInWithGoogle,
          createUserWithEmailAndPassword,
          signInWithEmailAndPassword,
          sendPasswordResetEmail,
          sendSignInLinkToEmail,
          reSendSignInLinkToEmail,
          waitProfile,
          getAssetRecord,
          checkNotificationsEnabled,
          enableNotifications,
          logout,
          runFlow,
          search,
          oauth,
          authHandler,
          emailKey,
          oauthStateKey,
        },
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
