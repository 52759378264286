import slugify from "react-slugify";

import { generateRefreshKey, GetVariableValue } from "../hooks";
import {
  BarComponentType,
  BrandingColorType,
  getMediaResourceUrl,
  ISize,
  setColors,
  TabBar,
  TabBarItem,
} from "./index";

export const createTabBar = async (
  id: string,
  tabBar: TabBar,
  screen: HTMLElement,
  getVariableValue: GetVariableValue
) => {
  const { backgroundColor = { constant: `@${BrandingColorType.background}` } } =
    tabBar;
  const height = await getVariableValue({
    numberConstant: ISize.bottomScreenMargins,
  });
  const surfaceVariant = await getVariableValue({
    colorConstant: `@${BrandingColorType.surfaceVariant}`,
  });

  const screenTabBar = document.createElement("div");
  screen.appendChild(screenTabBar);

  screenTabBar.id = id;
  screenTabBar.style.zIndex = "2";
  screenTabBar.style.position = "fixed";
  screenTabBar.style.left = "0";
  screenTabBar.style.bottom = "0";
  screenTabBar.style.width = "100%";
  screenTabBar.style.height = `${height}px`;
  screenTabBar.style.padding = "5px 0";
  screenTabBar.style.boxSizing = "border-box";
  screenTabBar.style.display = "flex";
  screenTabBar.style.justifyContent = "space-around";
  screenTabBar.style.alignItems = "flex-start";
  screenTabBar.style.backgroundBlendMode = "hard-light";
  screenTabBar.style.borderWidth = "0";
  screenTabBar.style.borderStyle = "solid";
  screenTabBar.style.borderColor = surfaceVariant;
  screenTabBar.style.borderTopWidth = "1px";

  await setColors(id, screenTabBar, getVariableValue, backgroundColor);

  return screenTabBar;
};

export const setTabs = async (
  screenName: string,
  tabBar: TabBar,
  screenTabBar: HTMLElement,
  fontFamily: string,
  desktopMode: boolean,
  navigate: (pathname: string) => void,
  getVariableValue: GetVariableValue
) => {
  const { tabs = [] } = tabBar;
  const visibleTabs: TabBarItem[] = [];
  for (let i = 0; i < tabs.length; i++) {
    const tab = tabs[i];
    const { visible } = tab;
    const visibleValue = await getVariableValue({
      ...visible,
      booleanConstant: visible.constant,
    });
    if (visibleValue) {
      visibleTabs.push(tab);
    }
  }
  for (const el of visibleTabs) {
    const { image, title, screen } = el;
    const id = `${BarComponentType.tab}.${screen}.${screen}`;
    const isActive = screen === screenName;

    const tab = document.createElement("div");
    screenTabBar.appendChild(tab);

    tab.id = id;
    tab.style.position = "relative";
    tab.style.display = "flex";
    tab.style.flexDirection = "column";
    tab.style.alignItems = "center";
    tab.style.gap = "4px";
    tab.style.width = `calc(100% / ${tabs.length})`;

    if (desktopMode && isActive) {
      const onBackground = await getVariableValue({
        colorConstant: `@${BrandingColorType.onBackground}`,
      });
      const shadow = document.createElement("div");
      shadow.style.position = "absolute";
      shadow.style.top = "-8px";
      shadow.style.zIndex = "-1";
      shadow.style.width = "40px";
      shadow.style.height = "40px";
      shadow.style.borderRadius = "8px";
      shadow.style.background = `${onBackground}26`;
      tab.appendChild(shadow);
    }

    const imageElement = document.createElement("img");
    imageElement.style.width = "24px";
    imageElement.style.height = "24px";
    imageElement.style.maxHeight = "100%";
    imageElement.style.objectFit = "contain";
    const imageColor = {
      constant: desktopMode
        ? `@${BrandingColorType.onBackground}`
        : isActive
        ? `@${BrandingColorType.primary}`
        : `@${BrandingColorType.neutral}`,
    };
    const imageRefresh = async () => {
      const url = await getMediaResourceUrl(
        getVariableValue,
        { [generateRefreshKey(id, "image")]: imageRefresh },
        image,
        imageColor,
        imageElement
      );
      imageElement.setAttribute("src", url);
    };
    await imageRefresh();
    tab.appendChild(imageElement);

    const textElement = document.createElement("div");
    textElement.style.fontFamily = fontFamily;
    textElement.style.lineHeight = "12px";
    textElement.style.fontSize = "10px";
    textElement.style.textAlign = "center";
    textElement.style.color = await getVariableValue({
      colorConstant: `@${
        desktopMode
          ? BrandingColorType.onBackground
          : isActive
          ? BrandingColorType.primary
          : BrandingColorType.neutral
      }`,
    });
    const textRefresh = async () => {
      textElement.innerHTML = await getVariableValue(
        { textConstant: title },
        { [generateRefreshKey(id, "title")]: textRefresh }
      );
    };
    await textRefresh();
    tab.appendChild(textElement);

    tab.className = "clickable";
    tab.onclick = () => navigate(`/${slugify(screen)}`);
  }
};
